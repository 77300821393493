import { useContext } from 'react';
import { authHeader } from '../../../components/helpers';
import { UserContext } from '../../../contexts/user.context';
import { TenantContext } from '../../../contexts/tenant.context';

const validateAccessEndpoint = "/api/users/validateAccess";
const authenticateEndpoint = "/api/users/authenticate";
const authenticatedEndpoint = "/api/users/authenticated";
const onboardRequestEndpoint = "/api/users/onboardRequest";

// auth observer
const onAuthStateChangedListener = (callback) => {
	// observer
	// need to create in backend as observable event
	//onAuthStateChanged(auth, callback, errorCallback, completedCallback);
}
/*
const AuthStateChanged = (systemAccess) => {
	console.log(`system-access.AuthStatChanged: ${systemAccess}`);
	
	const { setUserProfile } = useContext(UserContext);
	const { setTenantProfile } = useContext(TenantContext);
	
	// set context which should update rest of UI
	setUserProfile(systemAccess ? systemAccess.userProfile : null);
	setTenantProfile(systemAccess ? systemAccess.tenantProfile : null);
}
*/
export const ValidateAccess = async () => {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Origin': `${process.env.APP_ORIGIN}`,
			'X-CSRF-Token': 'Fetch',
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Request-Method': 'POST',
		},
		crossDomain: true,
		headers: authHeader()
	};

	console.log(requestOptions);
	return fetch(`${process.env.REACT_APP_API}${validateAccessEndpoint}`, requestOptions)
		.then(handleResponse)
		.then(jwtToken => {
			console.log(jwtToken);

			// store jwt token in local storage to keep user logged in between page refreshes
			sessionStorage.setItem('jwtToken', jwtToken.jwtToken);
//			localStorage.setItem('jwtToken', jwtToken.jwtToken);

			const requestOptions = {
				method: 'POST',
				headers: {
					'Origin': `${process.env.APP_ORIGIN}`,
					'X-CSRF-Token': 'Fetch',
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Access-Control-Request-Method': 'POST',
					'Authorization': `${jwtToken.jwtToken}`,
					'jwtToken': `${jwtToken.jwtToken}`
				},
				crossDomain: true,
				credentails: 'include'
			};

			console.log(requestOptions);
			return fetch(`${process.env.REACT_APP_API}${authenticatedEndpoint}`, requestOptions)
				.then(handleResponse)
				.then(systemAccess => {
					// store user details
					// Probably do not need this here
					sessionStorage.setItem('systemAccess', JSON.stringify(systemAccess));
					//sessionStorage.setItem('systemAccess.userProfile', JSON.stringify(userProfile));
					//sessionStorage.setItem('systemAccess.currentTenant', JSON.stringify(currentTenant));

					//AuthStateChanged(systemAccess);
					return systemAccess;
				});
			//.then(userProfile => {
			//    // store user details
			//    sessionStorage.setItem('userProfile', JSON.stringify(userProfile));
			//    localStorage.setItem('userProfile', JSON.stringify(userProfile));

			//    return userProfile;
			//});
		});
}

export const AuthenticateBasic = async (userName, password) => {
	const requestOptions = {
		method: 'POST',
		headers: {
			'Origin': `${process.env.APP_ORIGIN}`,
			'X-CSRF-Token': 'Fetch',
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Request-Method': 'POST',
		},
		crossDomain: true,
		credentails: 'include',
		userName: userName,
		password: password,
	};

	console.log(requestOptions);
	return fetch(`${process.env.REACT_APP_API}${authenticateEndpoint}?userName=${encodeURIComponent(userName)}&password=${encodeURIComponent(password)}`, requestOptions)
		.then(handleResponse)
		.then(jwtToken => {
			console.log(jwtToken);

			// store jwt token in local storage to keep user logged in between page refreshes
			sessionStorage.setItem('jwtToken', jwtToken.jwtToken);
			localStorage.setItem('jwtToken', jwtToken.jwtToken);

			const requestOptions = {
				method: 'POST',
				headers: {
					'Origin': `${process.env.APP_ORIGIN}`,
					'X-CSRF-Token': 'Fetch',
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Access-Control-Request-Method': 'POST',
					'Authorization': `${jwtToken.jwtToken}`,
					'jwtToken': `${jwtToken.jwtToken}`
				},
				crossDomain: true,
				credentails: 'include'
			};

			console.log(requestOptions);
			return fetch(`${process.env.REACT_APP_API}${authenticatedEndpoint}`, requestOptions)
				.then(handleResponse)
				.then(systemAccess => {
					// store user details
					// Probably do not need this here
					sessionStorage.setItem('systemAccess', JSON.stringify(systemAccess));
					//sessionStorage.setItem('systemAccess.userProfile', JSON.stringify(userProfile));
					//sessionStorage.setItem('systemAccess.currentTenant', JSON.stringify(currentTenant));

					//AuthStateChanged(systemAccess);
					return systemAccess;
				});
			//.then(userProfile => {
			//    // store user details
			//    sessionStorage.setItem('userProfile', JSON.stringify(userProfile));
			//    localStorage.setItem('userProfile', JSON.stringify(userProfile));

			//    return userProfile;
			//});
		})
		.catch(error => {
			console.log(error);
			console.log(error.name);
			console.log(error.message);
			sessionStorage.setItem('jwtToken', null);
			localStorage.setItem('jwtToken', null);
			//throw error;
			return Promise.reject(error);
		});
}

const qboConnect = async (callback) => {
	const connectEndpoint = `/api/quickbooks/online/connectToIntuit`;

	var winConnect = window.open(`${process.env.REACT_APP_API}${connectEndpoint}`, 'Connect To Intuit Window', 'toolbar=0,status=0,width=800,height=650');

	var promise = new Promise((resolve, reject) => {
		var timer = setInterval(function() {
			if (winConnect.closed) {
				clearInterval(timer);
				var jwtToken = sessionStorage.getItem("jwtToken");
				var onboardRequest = sessionStorage.getItem("onboardRequest");


				const requestOptions = {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'jwtToken': `${jwtToken}`
					},
					body: JSON.stringify(onboardRequest),
					crossDomain: true,
					credentails: 'include'
				};

				return fetch(`${process.env.REACT_APP_API}${onboardRequestEndpoint}`, requestOptions)
					.then(handleResponse)
					.then(userProfile => {
						// store user details and jwt token in local storage to keep user logged in between page refreshes
						sessionStorage.setItem('userProfile', JSON.stringify(userProfile));

						resolve(userProfile);
					});

			}
		}, 1000)
	});

	return promise;
}

const qboSignIn = async (callback) => {
	const signInEndpoint = `/api/quickbooks/online/signInWithIntuit?use=authentication`;

	var winSignIn = window.open(`${process.env.REACT_APP_API}${signInEndpoint}`, 'Sign In With Intuit Window', 'toolbar=0,status=0,width=800,height=650');

	var promise = new Promise((resolve, reject) => {
		var timer = setInterval(function() {
			if (winSignIn.closed) {
				clearInterval(timer);
				var lastAuthToken = sessionStorage.getItem("lastAuthToken");
				sessionStorage.setItem('jwtToken', lastAuthToken);

				const requestOptions = {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'jwtToken': `${lastAuthToken}`
					},
					crossDomain: true,
					credentails: 'include'
				};

				return fetch(`${process.env.REACT_APP_API}${authenticatedEndpoint}`, requestOptions)
					.then(handleResponse)
					.then(systemAccess => {
						// store user details
						sessionStorage.setItem('systemAccess', JSON.stringify(systemAccess));

						return systemAccess;
					});
				//.then(userProfile => {
				//    // store user details and jwt token in local storage to keep user logged in between page refreshes
				//    sessionStorage.setItem('userProfile', JSON.stringify(userProfile));

				//	resolve(userProfile);
				//});

			}
		}, 1000)
	});

	return promise;
}

const SignOut = async () => {
	console.log('logging out user');

	// remove user from local storage to log user out
	localStorage.removeItem('jwtToken');
	localStorage.removeItem('token');
	localStorage.removeItem('systemAccess');
	sessionStorage.removeItem('jwtToken');
	sessionStorage.removeItem('user');
	sessionStorage.removeItem('userProfile');
	sessionStorage.removeItem('tenantProfile')
	sessionStorage.removeItem('systemAccess');

	//AuthStateChanged(null);
	return null;
}

const onboardTenant = async (onboardRequest) => {
	const requestOptions = {
		method: 'POST',
		crossDomain: true,
		headers: {
			'Origin': `${process.env.APP_ORIGIN}`,
			'X-CSRF-Token': 'Fetch',
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Request-Method': 'POST',
		},
		body: JSON.stringify(onboardRequest)
	};

	console.log(`${process.env.REACT_APP_API}${onboardRequestEndpoint}`);
	return fetch(`${process.env.REACT_APP_API}${onboardRequestEndpoint}`, requestOptions).then(handleResponse);
}

const deleteUser = async (id) => {
	const requestOptions = {
		method: 'DELETE',
		crossDomain: true,
		headers: authHeader()
	};

	return fetch(`${process.env.REACT_APP_API}/users/${id}`, requestOptions).then(handleResponse);
}

const deleteTenant = async (id) => {
	const requestOptions = {
		method: 'DELETE',
		crossDomain: true,
		headers: authHeader()
	};

	return fetch(`${process.env.REACT_APP_API}/users/${id}`, requestOptions).then(handleResponse);
}

const handleResponse = async (response) => {
	console.log('handle response');
	console.log({ response });
	return response.json().then(json => {
		const data = json;

		if (!response.ok) {
			if (response.status === 401) {
				// auto logout if 401 response returned from api
				SignOut();
				//                location.reload(true);
			}

			const error = (data && data.message) || response.statusText;
			return Promise.reject(error);
		}

		return data;
	});
}

export const receiveMessage = (event) => {
	// Do we trust the sender of this message?  (might be
	// different from what we originally opened, for example).
	//  if (event.origin !== "http://localhost:9080")
	//    return;

	if (typeof event.data === "string" && event.data.indexOf("Bearer ") === 0) {
		sessionStorage.setItem('lastAuthToken', event.data)
	}
	// event.source is popup
	// event.data is "hi there yourself! the secret response is: rheeeeet!"
}

window.addEventListener("message", receiveMessage, false);

export const SystemAccessService = {
	//onAuthStateChangedListener,
	ValidateAccess,
	AuthenticateBasic,
	qboConnect,
	qboSignIn,
	onboardTenant,
	deleteTenant,
	deleteUser,
	SignOut
};
