import { useState, useEffect, useContext, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//import { connect } from 'react-redux';
//import { Field, reduxForm } from 'redux-form'

import FormInput from '../../components/form-input/form-input.component';
import ExchangePartnerList from './components/exchange-partner-list.component';
import Spinner from '../../components/spinner/spinner.component';

//import renderField from '../../../../js/components/renderField'
import BasicAuthModal from './components/BasicAuthModal'
//import { Modal, Button } from "react-bootstrap";
import { BasicAuth } from './components/BasicAuth';

//import { TenantContext } from '../../contexts/tenant.context';
import { selectExchangesMap, selectExchangesIsLoading } from '../../store/reducers/exchanges/exchange.selector';
import { fetchExchangesStart } from '../../store/reducers/exchanges/exchange.action'
import { selectCurrentTenant } from '../../store/reducers/tenant/tenant.selector';
import { setCurrentTenant, fetchTenantStart } from '../../store/reducers/tenant/tenant.action';

import { tenantActions } from './actions';
import './tenant-configuration.styles.scss';

const TenantConfiguration = () => {
	console.log('TenantConfiguration render');

	const [pageState, setPageState] = useState({ submitted: false });
	const dispatch = useDispatch();
	const navigate = useNavigate();
	
	console.log(pageState);

	useEffect(() => {
//		dispatch(fetchTenantStart());
		console.log('tenant fetch exchanges in useEffect')
		dispatch(fetchExchangesStart());
		//dispatch(fetchExchangesAsync());
//		const exchangePartners = async () => {
			//dispatch(exchangesSaga());
			//			const exchangesArray = await exchangePartnerActions.getAvailableExchangePartners();
			//			dispatch(setExchangePartners(exchangesArray));	
//		}
	}, []);

  	const isExchangesLoading = useSelector(selectExchangesIsLoading);
	console.log('isExchangesLoading: ', { isExchangesLoading });

	const tenantProfile = useSelector(selectCurrentTenant);
	console.log('tenantProfile: ', {tenantProfile});
	//	const { tenantProfile, setTenantProfile } = useContext(TenantContext);
	const [formFields, setFormFields] = useState(tenantProfile);

	const handleSaveTenant = async (event) => {
		console.error('handleSaveTenant called');
		event.preventDefault();

		//this.setState({ submitted: true });

		if (tenantProfile) {
			//			this.setState({
			//				systemAccess: dispatch(tenantActions.save(tenantProfile.tenantId))
			//			});
			try {
				await tenantActions.save(tenantProfile)
					.then(response => {
						console.log('Saved tenant profile changes: ', response);
						console.log(`tenantProfile: ${response.currentTenant}`);
						setCurrentTenant(response.currentTenant);
					});
			}
			catch (error) {
				console.error('save tenant profile encountered an error', error);
			}
		}
	}

	const onChangeHandler = (event) => {
		const { name, value } = event.target;
	};

	const handleQBOAuth = (exchangePartner) => {
		// call tenantServices.exchangeQBOAuth
		alert(`QBO Auth - ${exchangePartner.exchangeName}`);

		//const { tenantProfile } = this.props;
		dispatch(tenantActions.qboExchangeAuth(tenantProfile.tenantId, exchangePartner.exchangePartnerId));
	}

	const handleBasicAuth = (exchangePartner) => {
		// popup configuration - get username and password and save
		alert(`Basic auth - ${exchangePartner.exchangeName}`);
		//		const { tenantProfile } = this.props;
		dispatch(tenantActions.basicExchangeAuth(tenantProfile.tenantId, exchangePartner.exchangePartnerId));
	}

	const getBasicAuthDetails = (exchangePartner) => {
		this.setState({ currentExchangePartner: exchangePartner });
		this.showModal();
	}

	const getBasicAuthDetailsBackup = (exchangePartner) => {
		this.setState({ currentExchangePartner: `${exchangePartner.exchangeName}` });
		this.showModal();
	}

	const showModal = () => {
		this.setState({
			showBasicAuth: true
		});
	};

	const hideModal = () => {
		this.setState({ showBasicAuth: false });
	};

	const saveBasicAuth = () => {
		var message = document.getElementById("epUserName").value;
		alert(message);

		let partnerId = this.state.currentExchangePartner.exchangePartnerId;
		let epUserName = document.getElementById("epUserName").value;
		let epPassword = document.getElementById("epPassword").value;

		//const { tenantProfile } = this.props;
		dispatch(tenantActions.basicExchangeAuth(tenantProfile.tenantId, partnerId, epUserName, epPassword));

		this.setState({ showBasicAuth: false });
	};


	const refreshTenant = () => {
		//dispatch(tenantActions.get(tenantProfile.tenantId));
		/*
		try {
			await tenantActions.get(tenantProfile.tenantId)
				.then(response => {
					console.log('Refreshed tenant profile: ', response);
					console.log(`tenantProfile: ${response.currentTenant}`);
					dispatch(setCurrentTenant(response.currentTenant));
				});
		}
		catch (error) {
			console.error('save tenant profile encountered an error', error);
		}
		*/
	}
	/*
		if (userProfile == null) {
			navigate("/systemAccess");
			//return <Redirect to="/login" />
		}
	*/
	return (
		<div className="page-container">
			<form onSubmit={handleSaveTenant}>
				<div className="tenant">
					<h2>Business Detail Configuration</h2>
					{pageState.loading && <em>Loading business configuration...</em>}
					{pageState.error && <span className="text-danger">ERROR: {pageState.error}</span>}
					{tenantProfile &&
						<Fragment>
							<div>
									<span>Company Details</span>
								<div className="tenant-details-container">
									<div className="tenant-company-container">
										<FormInput
											label='Tenant Identifier Code'
											type='text'
											required
											readOnly
											onChange={onChangeHandler}
											name='tenantProfile.tenantCode'
											value={tenantProfile.tenantCode}
										/>
										<FormInput
											label='Legal Company Name'
											type='text'
											required
											onChange={onChangeHandler}
											name='tenantProfile.legalName'
											value={tenantProfile.legalName}
										/>
										<FormInput
											label='Website'
											type='text'
											required
											onChange={onChangeHandler}
											name='tenantProfile.primaryUrl'
											value={tenantProfile.primaryUrl}
										/>
									</div>
									<div className="tenant-company-container">
									<span>Contact</span>
										<FormInput
											label='Email'
											type='text'
											required
											onChange={onChangeHandler}
											name='tenantProfile.contactDetail.email'
											value={tenantProfile.contactDetail.email}
										/>
										<FormInput
											label='Phone'
											type='tel'
											required
											onChange={onChangeHandler}
											name='tenantProfile.contactDetail.phone'
											value={tenantProfile.contactDetail.phone}
										/>
									</div>
								</div>
							</div>
							<div>
      {isExchangesLoading ? (
        <div><h4>Loading exchanges...</h4><Spinner/></div>
      ) : (
	<ExchangePartnerList currentTenant={tenantProfile} />
      )}
							</div>
							<div>
								<h3>Authorized Users</h3>
								<ul>
									{tenantProfile.userProfiles && tenantProfile.userProfiles.map((tenantUser, index) =>
										<li key={tenantUser.userProfileId}>
											{tenantUser.firstName + ' ' + tenantUser.lastName + ', ' + tenantUser.userType}
										</li>
									)}
								</ul>
							</div>
						</Fragment>
					}

					<span><input type="submit" value="Submit" /></span>
					<span><a onClick={refreshTenant}>Refresh</a></span>
				</div>
			</form>

			<BasicAuthModal showModal={pageState.showBasicAuth} handleCancelModal={hideModal} handleSaveModal={saveBasicAuth}>
				<p>Add authentication details for {pageState.currentExchangePartner && pageState.currentExchangePartner.exchangeName}</p>
				<div className="modal-body">
					<BasicAuth />
				</div>
			</BasicAuthModal>
		</div>
	);
}

export default TenantConfiguration;