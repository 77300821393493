import { useSelector, useDispatch } from 'react-redux';

import { tenantActions } from '../actions';

import Button from '../../../components/button/button.component';

//import { selectTenantExchangePartners } from '../../store/cart/cart.selector';

import {
	ExchangePartnerContainer,
	BaseSpan,
	FlagSpan,
	AuthButton,
} from './exchange-partner.styles';

const ExchangePartner = ({ exchangePartner, tenantExchangeConfiguration, onConfigHandler, onRevokeHandler }) => {
	const { exchangeName, exchangeDescription } = exchangePartner;
	console.log(onConfigHandler);

	const dispatch = useDispatch();

	return (
		<ExchangePartnerContainer>
			<FlagSpan><input type="checkbox" /></FlagSpan>
			<BaseSpan> {exchangeName} </BaseSpan>
			<BaseSpan> {exchangeDescription} </BaseSpan>
			<BaseSpan>
			{onConfigHandler &&
				<Button onClick={onConfigHandler}>Configure</Button>}
			{onRevokeHandler &&
				<Button onClick={onRevokeHandler}>Revoke</Button>}
			{!onRevokeHandler && !onConfigHandler &&
				'---'}
			</BaseSpan>
			<FlagSpan><input type="radio" /></FlagSpan>
		</ExchangePartnerContainer>
	);
};

export default ExchangePartner;