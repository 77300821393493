import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setCurrentUser } from './store/reducers/user/user.action';
import { setCurrentTenant } from './store/reducers/tenant/tenant.action';

import { Routes, Route } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import Navigation from './routes/navigation/navbar.component';
import Authentication from './stories/SystemAccess/components/authentication/authentication.component';
import SignOut from './stories/SystemAccess/components/sign-out/sign-out.component';
//import SignIn from './stories/SystemAccess/scenes/SignInPage/sign-in.component';
//import SignUp from './stories/SystemAccess/scenes/SignUpPage/sign-up.component';
import Dashboard from './stories/Dashboard/dashboard.component';
import PrintBarcodes from './stories/PrintBarcodes/scenes/print-barcodes.component';
import TenantConfiguration from './stories/TenantConfiguration/tenant-configuration.component';
import Inventory from './stories/Inventory/scenes/inventory.component';
import InventorySync from './stories/Inventory/scenes/inventory-sync.component';
import SalesSync from './stories/Sales/scenes/sales-sync.component';
import Footer from './stories/Footer/Footer';
import Privacy from './stories/Privacy/Privacy.jsx';
import EULA from './stories/EULA/EULA.jsx';
import './App.scss';
import CardList from './components/card-list/card-list.component';
import SearchBox from './components/search-box/search-box.component';

import {SystemAccessService} from './interface/services/system-access/system-access.service';

const DashboardLocal = () => {
	return (
		<div>
			<h1>I am the dashboard</h1>
		</div>
	);
};

const UserProfile = () => {
	return (
		<h1>I am the user profile page</h1>
	);
};

const TenantProfile = () => {
	return (
		<h1>I am the tenant profile page</h1>
	);
};

const SelectTenant = () => {
	return (
		<h1>I am the select tenant page</h1>
	);
};

//const Onboarding = () => {
//	return (
//		<SignUp />
//	);
//};

//const PrintBarcodes = () => {
//	return (
//		<h1>I am the print barcodes page</h1>
//	);
//};

const PrintLabels = () => {
	return (
		<h1>I am the print labels page</h1>
	);

};

//const Inventory = () => {
//	return (
//		<h1>I am the inventory page</h1>
//	);
//};

const Sales = () => {
	return (
		<h1>I am the sales page</h1>
	);

};

// changed to functional component from class componenet
const App = () => {
	const dispatch = useDispatch();
	
	//const dispatch = useDispatch();
	useEffect(() => {
		console.log('initialize user, tenant');
		//SystemAccessService.ValidateAccess();

		// get user - part of SignIn page
		// initialize to null on startup
		// check auth changed
		// set current user (default)
//		setCurrentUser(null);
//		setCurrentTenant(null);
		//dispatch(setCurrentTenant(null))
	}, []);

	const navigate = useNavigate();
	
	useEffect(() => {
    	// Function to handle sessionStorage change
    	const handleSessionStorageChange = () => {
      		// Check if the sessionStorage value has changed
      		console.log('sessionStorage changed');
      		if (sessionStorage.getItem('jwtToken') === null) {
        		// Navigate to the desired page
//        		history.push('/your-target-page');
      			console.log('jwtToken changed to null');
				navigate('/system-access');
      		}
      		console.log('jwtToken is not null');
    	};

    	// Add event listener for sessionStorage change
    	window.addEventListener('storage', handleSessionStorageChange);

    	// Clean up the event listener when the component unmounts
    	return () => {
      		window.removeEventListener('storage', handleSessionStorageChange);
    	};
//  	});
  	}, [sessionStorage]);
	
	console.log('App render');
	return (
		<div>
			<Routes>
				<Route path="/" element={<Navigation />} >
					<Route index element={<Dashboard />} /> {/*index indicates to show if outer path is selected */}
					<Route path="system-access" element={<Authentication />} />
					<Route path="sign-out" element={<SignOut />} />
					<Route path="user-view" element={<UserProfile />} />
					<Route path="tenant-view" element={<TenantConfiguration />} />
					<Route path="select-tenant" element={<SelectTenant />} />
					{/*<Route path="onboarding" element={<Onboarding />} />*/}
					<Route path="inventory" element={<Inventory />} />
					<Route path="inventory-sync" element={<InventorySync />} />
					<Route path="sales" element={<Sales />} />
					<Route path="sales-main" element={<Sales />} />
					<Route path="sales-sync" element={<SalesSync />} />
					<Route path="print-barcodes" element={<PrintBarcodes />} />
					<Route path="print-labels" element={<PrintLabels />} />
					<Route path="privacy.jsx" element={<Privacy />} /> 
					<Route path="EULA.jsx" element={<EULA />} />
				</Route>
			</Routes>
			<Footer />
		</div>
	);
}

export default App;
