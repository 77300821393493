import { Fragment, useContext, useState, useEffect } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { Navbar, NavbarBrand, Nav, NavItem, NavDropdown, MenuItem, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as NavLogo } from '../../assets/commerce-conx.svg';
import { ReactComponent as GearIcon } from '../../assets/gear-svgrepo-com.svg';

import { selectCurrentUser } from '../../store/reducers/user/user.selector';
import { selectCurrentTenant } from '../../store/reducers/tenant/tenant.selector';
//import { UserContext } from '../../contexts/user.context';
//import { TenantContext } from '../../contexts/tenant.context';

import 'bootstrap/dist/css/bootstrap.min.css';
import './navbar.styles.scss';


// Fragment does not render, it just returns the html within to be rendered in the calling component
// This elements the need to wrap the retun in a single html element such as <div/>
// In this example there is still a div wrapping then navigation portion for CSS purposes but the Outlet is outside

// Outlet tells the subpages to be rendered in that section
const Navigation = () => {
	// get userProfile stored in session context, causes re-render whenever userProfile is changed
	//console.log('Navigation rendering');
	const userProfile = useSelector(selectCurrentUser);
	const tenantProfile = useSelector(selectCurrentTenant);
	//const { userProfile } = useContext(UserContext);
	console.log(userProfile);
	//const { tenantProfile } = useContext(TenantContext);
	console.log(tenantProfile);

	const navigate = useNavigate();
	//let jwtToken = sessionStorage.getItem('jwtToken');
	
	console.log('navbar useEffect');
	useEffect(() => {
		console.log('IN navbar useEffect');
//		let navigate = useNavigate();
    	// Function to handle sessionStorage change
    	const handleSessionStorageChange = () => {
      		// Check if the sessionStorage value has changed
      		console.log('sessionStorage changed');
			if (sessionStorage.getItem('jwtToken') === null || sessionStorage.getItem('jwtToken') === 'null') {
        		// Navigate to the desired page
//        		history.push('/your-target-page');
      			console.log('jwtToken changed to null');
				navigate('/system-access');
      		}
      		else {
      			console.log('jwtToken is not null');
      		}
    	};

    	// Add event listener for sessionStorage change
    	window.addEventListener('storage', handleSessionStorageChange);

    	// Clean up the event listener when the component unmounts
    	return () => {
			console.log('remove handleSessionStorageChange');
      		window.removeEventListener('storage', handleSessionStorageChange);
    	};
//  	});
  	}, [navigate]);


	return (
		<Fragment>
			<Navbar className="navigation" collapseOnSelect variant="light" expand="md" >
				<Container fluid>
					<NavLink className=".logo-container" to="/">
						<NavLogo className="logo" />
					</NavLink>
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse id="responsive-navbar-nav" >
						{userProfile ? (
							<Fragment>

								<Nav className="nav-links-container">
									{
										tenantProfile ? (
											<Fragment>
												<NavDropdown
													id="nav-dropdown"
													title="Inventory"
													menuVariant="medium"
												>
													<NavLink className="nav-link" to="/inventory">Inventory</NavLink>
													<NavLink className="nav-link" to="/inventory-sync">Sync</NavLink>
												</NavDropdown>
												<NavDropdown
													id="nav-dropdown"
													title="Sales"
													menuVariant="medium"
												>
													<NavLink className="nav-link" to="/sales">Sales</NavLink>
													<NavLink className="nav-link" to="/sales-sync">Sync</NavLink>
												</NavDropdown>
												<NavDropdown
													id="nav-dropdown"
													title="Tools"
													menuVariant="medium"
												>
													<NavLink className="nav-link" to="/print-barcodes">Print Barcodes</NavLink>
													<NavDropdown.Item eventKey="1" href="/print-barcodes">Print Barcodes</NavDropdown.Item>
													<NavDropdown.Item eventKey="2" href="/print-labels">Print Labels</NavDropdown.Item>
													<NavDropdown.Divider />
												</NavDropdown>
											</Fragment>
										) : (
											<div>
												<NavLink className="nav-link" to="/system-access">System</NavLink>
											</div>
										)
									}
								</Nav>
							</Fragment>
						) : (
							<Fragment>
								<Nav className="nav-links-container">
									<div />
								</Nav>
							</Fragment>
						)}
						{userProfile ? (
							<Fragment>
								<Nav className="nav-user-links-container">
									<NavDropdown className="nav-dropdown-gear"
										renderMenuOnMount={true}
										align="end"
										title={
											<div className="pull-left">
												<div>{userProfile.firstName}</div>
												<div><GearIcon className="access-config-icon" /></div>
											</div>
										}
									>
										<NavDropdown.Item className="nav-link" href="/user-view">My Profile</NavDropdown.Item>
										{tenantProfile &&
											<NavDropdown.Item className="nav-link" href="./tenant-view">{tenantProfile.legalName}</NavDropdown.Item>
										}
										<NavDropdown.Divider />
										<NavDropdown.Item className="nav-link" href="/sign-out">Sign Out</NavDropdown.Item>
									</NavDropdown>
								</Nav>
							</Fragment>
						) : (
							<Fragment>
								<Nav className="nav-user-links-container">
									<NavLink to="/system-access">Join the FUN</NavLink>
								</Nav>
							</Fragment>
						)}
					</Navbar.Collapse>
				</Container>
			</Navbar>
			<Outlet />
		</Fragment >
	);

};

export default Navigation;