import { createAction } from '../reducer.util';
import { TENANT_ACTION_TYPES } from './tenant-action.types';

export const setCurrentTenant = (tenant) => 
	createAction(TENANT_ACTION_TYPES.SET_CURRENT_TENANT, tenant);

export const fetchTenantStart = () =>
	createAction(TENANT_ACTION_TYPES.FETCH_TENANT_START);

export const fetchTenantSuccess = (tenant) =>
	createAction(TENANT_ACTION_TYPES.FETCH_TENANT_SUCCESS, tenant);

export const fetchTenantFailed = (error) =>
	createAction(TENANT_ACTION_TYPES.FETCH_TENANT_FAILED, error);
