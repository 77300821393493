import { tenantConstants } from '../components/constants';
import { tenantService } from '../services';
//import { alertActions } from '../../../interface/actions';
//import { history } from '../../../components/helpers';

function get(id) {
    return dispatch => {
        dispatch(request(id));

        tenantService.getById(id)
            .then(
                currentTenant => dispatch(success(currentTenant)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: tenantConstants.TENANT_GET_REQUEST } }
    function success(tenant) { return { type: tenantConstants.TENANT_GET_SUCCESS, tenant } }
    function failure(error) { return { type: tenantConstants.TENANT_GET_FAILURE, error } }
}

function save(id) {
    return dispatch => {
        dispatch(request(id));

        tenantService.save(id)
            .then(
                currentTenant => dispatch(success(currentTenant)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: tenantConstants.TENANT_SAVE_REQUEST } }
    function success(tenant) { return { type: tenantConstants.TENANT_SAVE_SUCCESS, tenant } }
    function failure(error) { return { type: tenantConstants.TENANT_SAVE_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));
/*
        inventoryService.delete(id)
            .then(
                inventory => dispatch(success(id)),
                error => dispatch(failure(id, error.toString()))
            );
            */
    };

    function request(id) { return { type: tenantConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: tenantConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: tenantConstants.DELETE_FAILURE, id, error } }
}

function basicExchangeAuth(tenantId, exchangePartnerId, exchangeUserName, exchangePassword) {
    return dispatch => {
        //dispatch(request(tenantId));
        dispatch(request(exchangePartnerId));

        tenantService.basicExchangeAuth(tenantId, exchangePartnerId, exchangeUserName, exchangePassword)
            .then(
            	exchangeAuthSaved => dispatch(success(exchangePartnerId)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request(exchangePartnerId) { return { type: tenantConstants.EXCHANGE_AUTH_REQUEST, exchangePartnerId } }
    function success(exchangeAuthSaved) { return { type: tenantConstants.EXCHANGE_AUTH_SUCCESS, exchangeAuthSaved } }
    function failure(error) { return { type: tenantConstants.EXCHANGE_AUTH_FAILURE, error } }
}

function basicExchangeAuthBackup(tenantId, exchangePartnerId) {
    return dispatch => {
        //dispatch(request(tenantId));
        dispatch(request(exchangePartnerId));

        tenantService.exchangeAuth(basicExchangeAuth(tenantId, exchangePartnerId))
            .then(
            	exchangeAuthSaved => dispatch(success(exchangePartnerId)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request(exchangePartnerId) { return { type: tenantConstants.EXCHANGE_AUTH_REQUEST, exchangePartnerId } }
    function success(exchangeAuthSaved) { return { type: tenantConstants.EXCHANGE_AUTH_SUCCESS, exchangeAuthSaved } }
    function failure(error) { return { type: tenantConstants.EXCHANGE_AUTH_FAILURE, error } }
}

function qboExchangeAuth(tenantId, exchangePartnerId) {
    return dispatch => {
        //dispatch(request(tenantId));
        dispatch(request(exchangePartnerId));

//        tenantService.exchangeAuth(qboExchangeAuth(tenantId, exchangePartnerId))
        tenantService.qboExchangeAuth(tenantId, exchangePartnerId)
            .then(
            	exchangeAuthSaved => dispatch(success(exchangePartnerId)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: tenantConstants.EXCHANGE_AUTH_REQUEST } }
    function success(exchangePartnerId) { return { type: tenantConstants.EXCHANGE_AUTH_SUCCESS, exchangePartnerId } }
    function failure(error) { return { type: tenantConstants.EXCHANGE_AUTH_FAILURE, error } }
}

function squareExchangeAuth(tenantId, exchangePartnerId) {
    return dispatch => {
        //dispatch(request(tenantId));
        dispatch(request(exchangePartnerId));

        tenantService.exchangeOAuth2(tenantId, exchangePartnerId)
            .then(
            	exchangeAuthSaved => dispatch(success(exchangePartnerId)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: tenantConstants.EXCHANGE_AUTH_REQUEST } }
    function success(exchangePartnerId) { return { type: tenantConstants.EXCHANGE_AUTH_SUCCESS, exchangePartnerId } }
    function failure(error) { return { type: tenantConstants.EXCHANGE_AUTH_FAILURE, error } }
}

function oauth2Connect(tenantId, exchangePartnerId) {
	console.log(`in tenantActions.oauth2Connect (${tenantId}, ${exchangePartnerId})`);
    return dispatch => {
        //dispatch(request(tenantId));
        dispatch(request(exchangePartnerId));

//        tenantService.exchangeAuth(qboExchangeAuth(tenantId, exchangePartnerId))
        tenantService.oauth2Connect(tenantId, exchangePartnerId)
            .then(
            	exchangeAuthSaved => dispatch(success(exchangePartnerId)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: tenantConstants.EXCHANGE_AUTH_REQUEST } }
    function success(exchangePartnerId) { return { type: tenantConstants.EXCHANGE_AUTH_SUCCESS, exchangePartnerId } }
    function failure(error) { return { type: tenantConstants.EXCHANGE_AUTH_FAILURE, error } }
}

export const tenantActions = {
    get,
	save,
	basicExchangeAuth,
	qboExchangeAuth,
	squareExchangeAuth,
	oauth2Connect,
    delete: _delete
};
