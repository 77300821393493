import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

//import { AUTHENTICATED_ACTION_TYPES } from '../../../../store/reducers/authentication/authenticated-action.types';
//import { setSystemAccess } from '../../../../store/reducers/authentication/authentication.action';
import { setCurrentUser } from '../../../../store/reducers/user/user.action';
import { setCurrentTenant } from '../../../../store/reducers/tenant/tenant.action';

//import { UserContext } from '../../../../contexts/user.context';
//import { TenantContext } from '../../../../contexts/tenant.context';
import AuthStateChanged from '../../../../interface/components/auth-changed.component';
import Spinner from '../../../../components/spinner/spinner.component';

import FormInput from '../../../../components/form-input/form-input.component';
import Button from '../../../../components/button/button.component';
import logoLogin from '../../../../assets/commerce-conx.png';
import { Link } from 'react-router-dom';
//import { connect } from 'react-redux';
import QboSignIn from '../../components/qbo/qbo-signin.component';
import QboConnect from '../../components/qbo/qbo-connect.component';

//import { userActions } from '../../../../actions';
import { securityActions } from '../../../../interface/actions/security.actions';
import { SystemAccessService } from '../../../../interface/services/system-access/system-access.service';
import { useHistory } from 'react-router-dom';

import './signin.styles.scss';

const qboSignInUrl = `${process.env.REACT_APP_API}/api/quickbooks/online/connectToQuickbooks`;

const defaultFormFields = {
	email: '',
	password: '',
	submitted: false,
	loginAttempts: 0,
	loggingIn: false,
};

const SignIn = () => {
//	let systemAccess = null;
//	console.log(`initiate systemAccess: ${systemAccess}`);
	console.log(`rendering signIn`);
	
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [formFields, setFormFields] = useState(defaultFormFields);
	const [errorMessage, setErrorMessage] = useState();
	//const { loggingIn } = props;
	//const { email, password, submitted, loggingIn } = formFields;
	//console.log(formFields.loginAttempts);
	//console.log({loggingIn});

	// get the setter method for the UserProfile session storage	
	//const { userProfile, setUserProfile } = useContext(UserContext);
	//const { tenantProfile, setTenantProfile } = useContext(TenantContext);

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormFields({ ...formFields, [name]: value });
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		
		setFormFields({ ...formFields, 'errorMessage': '' });
		setErrorMessage(null);
		console.log(formFields);
		const loginAttempts = formFields.loginAttempts + 1;
		setFormFields({ ...formFields, 'loginAttempts': loginAttempts });
		setFormFields({ ...formFields, 'submitted': true });
		setFormFields({ ...formFields, 'loggingIn': true });
		console.log(formFields);
		console.log({ loginAttempts });

		if (formFields.email && formFields.password) {
			try {
				//await securityActions.authenticateBasicUser(formFields.email, formFields.password)
				await SystemAccessService.AuthenticateBasic(formFields.email, formFields.password)
					.then(response => {
						console.log('Logged in', response);
						console.log('userProfile', response.userProfile);
						console.log('tenantProfile', response.currentTenant);
						//setUserProfile(response.userProfile);
						//setTenantProfile(response.currentTenant);
						dispatch(setCurrentUser(response.userProfile));
						dispatch(setCurrentTenant(response.currentTenant));
						//dispatch(...state);

						navigate('/');
						//return (<AuthStateChanged systemAccess={response} />);
					});
			}
			catch (error) {
				console.log(error);
				console.log(error.message);
				if (!error?.response) {
					setErrorMessage('System Error - Please contact support');
				}
				else {
					setErrorMessage('Invalid username/password');
				}

				console.log(errorMessage);
			}
		}

		setFormFields({ ...formFields, ['submitted']: false });
		setFormFields({ ...formFields, ['loggingIn']: false });
	};

	// use for redirect back from provider (QBO, ECWID, etc) as the re-render occurs when we come back to page
	//useEffect(async () => {
	//	const response = await getQboCallback(auth);  // auth is singleton for the app commconx-api.component
	//	if (response) {
	//		const userRefDoc = await createUserFromAuth(response.user);
	//	}
	//}, []); // empty array means run the effect once

	//const logQboUser = async () => {
	//	const response = await signInWithQboPopup();
	//	console.log(response);
	//} 

	//	console.log(`check systemAccess: ${systemAccess}`);
	//	if (systemAccess != null) {
	//		<AuthStateChanged systemAccess={systemAccess} />
	//	}

	return (
		<div className='sign-up-container col-md-6 col-md-offset-3'>
			<h2>Sign In</h2>
			{formFields.loggingIn && <span>Logging in...</span>}
			{!formFields.loggingIn && errorMessage && <em>Login failed</em>}
			{errorMessage && <em>{errorMessage}</em>}
			<div>
				<form name='sign-in-form' onSubmit={handleSubmit}>
					<div>
						<FormInput
							label='Email (User Name for login)'
							type='email'
							required
							onChange={handleChange}
							name='email'
							value={formFields.email}
						/>
						<FormInput
							label='Password'
							type='password'
							required
							onChange={handleChange}
							name='password'
							value={formFields.password}
						/>
					</div>
					<div className='buttons-container'>
						<Button type='submit' disabled={formFields.loggingIn && true}>{formFields.loggingIn && <Spinner/>}{!formFields.loggingIn && 'Sign In'}</Button>
						<div><QboSignIn /></div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default SignIn; 