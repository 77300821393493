import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../../../contexts/user.context';
import { TenantContext } from '../../../contexts/tenant.context';

import FormInput from '../../../components/form-input/form-input.component';
import Button from '../../../components/button/button.component';

import { SalesService } from '../../../interface/services/sales/sales.service';
import SalesSyncResponse from './sales-sync-response.component';

const defaultFormFields = {
	sourceSystem: '',
	destinationSystems: [],
	extractToFile: false,
	filePath: '',
	startDate: '',
	endDate: '',
	submitted: false,
	loginAttempts: 0,
	syncing: false,
};

const SalesSync = () => {
	const [formFields, setFormFields] = useState(defaultFormFields);
	const [syncData, setSyncData] = useState(null);
	const [missingFields, setMissingFields] = useState(null);
	const navigate = useNavigate();

	// get the setter method for the UserProfile session storage	
	const { userProfile, setUserProfile } = useContext(UserContext);
	const { tenantProfile, setTenantProfile } = useContext(TenantContext);
	console.log(`userProfile for syncing: ${userProfile}`);

	const handleChange = (event) => {
		const { name, value } = event.target;
		console.log('handleChange' + name + value);
		setFormFields({ ...formFields, [name]: value });
	};

	const handleToggleCheckbox = (event) => {
		const { name, checked } = event.target;
		console.log('handletoggleCheckbox' + name + checked);
		setFormFields({ ...formFields, [name]: checked });
	};

	const handleSourceSystemChange = (event) => {
		const { name, value } = event.target;
		setFormFields({ ...formFields, [name]: value });
	};

	const handleSyncSinceLast = async (event) => {
		event.preventDefault();

		console.log('handleSyncSinceLast');
		console.log(formFields);
		setFormFields({ ...formFields, 'submitted': true });
		setFormFields({ ...formFields, 'syncing': true });
		console.log(formFields);
		//console.log({loggingIn});

		if (formFields.sourceSystem && formFields.destinationSystems) {
			setMissingFields(false);

			try {
				await SalesService.SyncOrders(formFields)
					.then(response => {
						console.log('Sync Response: ', response);
					});
			}
			catch (error) {
				console.error('Sales sync encountered an error', error);
				if (sessionStorage.getItem('jwtToken') === null) {
					navigate('/system-access');
				}
			}
		}
		else {
			console.log('Source and Destination fields required');
			setMissingFields(true);
		}

		setFormFields({ ...formFields, 'submitted': undefined });
		setFormFields({ ...formFields, 'syncing': undefined });
	};

	const handleSyncSelected = async (event, selectedDataRows) => {
		event.preventDefault();

		console.log('handleSyncSelected in sales-sync component');
		//console.log(selectedRows);  // always empty, no need to pass in
		console.log(selectedDataRows);

		setFormFields({ ...formFields, 'submitted': true });
		setFormFields({ ...formFields, 'syncing': true });

		if (formFields.sourceSystem && formFields.destinationSystems) {
			try {
				await SalesService.SyncSelectedOrders(formFields, {selectedDataRows})
					.then(response => {
						console.log('Sync Response: ', response);
						setSyncData(response);
					});
			}
			catch (error) {
				console.error('Sales sync encountered an error', error);
				if (sessionStorage.getItem('jwtToken') === null) {
					navigate('/system-access');
				}
			}
		}
		else {
			console.log('Source and Destination fields required');
			setMissingFields(true);
		}

		setFormFields({ ...formFields, 'submitted': undefined });
		setFormFields({ ...formFields, 'syncing': undefined });
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		console.log('handleSubmit');
		console.log(formFields);
		setFormFields({ ...formFields, 'submitted': true });
		setFormFields({ ...formFields, 'syncing': true });
		console.log(formFields);
		//console.log({loggingIn});

		if (formFields.sourceSystem && formFields.destinationSystems) {
			setMissingFields(false);

			try {
				await SalesService.SyncOrders(formFields)
					.then(response => {
						console.log('Sync Response: ', response);
						setSyncData(response);
						if (response.systemErrors != null && response.systemErrors.length > 0) {
							console.error('System error count: ' + response.systemErrors.length);
							console.error(response.systemErrors);
							sessionStorage.setItem('jwtToken', null);
							navigate('/system-access');
						}
					});
			}
			catch (error) {
				console.error('Sales sync encountered an error', error);
				if (sessionStorage.getItem('jwtToken') === null || sessionStorage.getItem('jwtToken') === 'null') {
					console.log('navigating to system-access');
					navigate('/system-access');
				}
				else {
					console.log('setting jwtToken to null');
					sessionStorage.setItem('jwtToken', null);
					navigate('/system-access');
				}
			}
		}
		else {
			console.log('Source and Destination fields required');
			setMissingFields(true);
		}

// if navigate above stays in place then these statements should be conditional
		setFormFields({ ...formFields, 'submitted': undefined });
		setFormFields({ ...formFields, 'syncing': undefined });
	};

	return (
		<div className="page-container">
			<h3>Sales</h3>
			{missingFields && <em>Source and Destination fields required</em>}
			{formFields.syncing && <h5>Syncing data...</h5>}
			<div>
				<form name='sync-sales-form' onSubmit={handleSubmit}>
					<div>
						<div className='rowDisplay'>
							<FormInput
								label='Source System'
								type='text'
								onChange={handleChange}
								name='sourceSystem'
								value={formFields.sourceSystem}
							/>
							<FormInput
								label='Destination Systems'
								type='text'
								onChange={handleChange}
								name='destinationSystems'
								value={formFields.destinationSystems}
							/>
						</div>
						<div className='rowDisplay'>
							<div className=' flex_col_left_fixed'>
							<FormInput
								label='Extract to File:'
								type='checkbox'
								onClick={handleToggleCheckbox}
								name='extractToFile'
								value={formFields.extractToFile}
							/>
							</div>
							<FormInput
								label='File Path'
								type='text'
								onChange={handleChange}
								name='filePath'
								value={formFields.filePath}
							/>
						</div>
						<div>
							<span>Date Range:</span>
							<FormInput
								label='Start Date'
								type='text'
								onChange={handleChange}
								name='startDate'
								value={formFields.startDate}
							/>
							<FormInput
								label='End Date'
								type='text'
								onChange={handleChange}
								name='endDate'
								value={formFields.endDate}
							/>
						</div>
					</div>
					<div className='buttons-container'>
						<Button type='submit' disabled={formFields.syncing && true}>Sync All</Button>
						<Button type='Button' disabled={formFields.syncing && true} onClick={handleSyncSinceLast}>Sync Since Last Import</Button>
					</div>
				</form>
			</div>
			{!formFields.syncing && syncData && syncData.hasErrors && <em>Sync contained errors</em>}
			{!formFields.syncing && syncData && syncData.destinationSyncList.length > 0 && <SalesSyncResponse syncData={syncData} handleSyncSelected={handleSyncSelected}/>}
			{!formFields.syncing && syncData && syncData.destinationSyncList.length === 0 && <h5>No data to sync</h5>}
		</div>
	)
}

export default SalesSync;