const getAllEndpoint = "/api/exchanges/";

const getAll = async () => {
	let jwtToken = localStorage.getItem("jwtToken");

	console.log(jwtToken);
	const requestOptions = {
		method: 'GET',
		headers: {
			'Origin': `${process.env.APP_ORIGIN}`,
			'X-CSRF-Token': 'Fetch',
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Request-Method': 'GET',
			'Authorization': `${jwtToken.jwtToken}`,
			'jwtToken': `${jwtToken.jwtToken}`
		},
		crossDomain: true,
		credentails: 'include',
	};

	console.log(requestOptions);
	return fetch(`${process.env.REACT_APP_API}${getAllEndpoint}`, requestOptions)
		.then(handleResponse)
		.then(exchangePartners => {
			console.log(exchangePartners);
			return exchangePartners;
		});
}

const handleResponse = async (response) => {
	console.log('handle response');
	console.log({ response });
	return response.json().then(json => {
		const data = json;

		if (!response.ok) {
			const error = (data && data.message) || response.statusText;
			return Promise.reject(error);
		}

		return data;
	});
}

export const ExchangePartnerService = {
	getAll,
};
