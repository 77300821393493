import { createSelector } from 'reselect';

// started with this
//export const selectExchangesMap = (state) => state.exchanges.exchangesMap;
/*
// went to this
export const selectExchangesMap = (state) => state.exchanges.exchanges
	//acc = accumulator
	.reduce((acc, exchange) => {
			const { exchangeCode, items } = exchange;
			acc[exchangeCode.toLowerCase()] = items;
			return acc;
		}, {})
*/
// ended up with this		
const selectExchangeReducer = (state) => state.exchanges;

export const selectExchanges = createSelector(
	[selectExchangeReducer],
	(exchangesSlice) => exchangesSlice.exchanges
);
/*
export const selectExchangesMap = createSelector(
	[selectExchangeReducer],
	(exchangesSlice) => {
		console.log('selecting exchangesMap');
		console.log(exchangesSlice);
		exchangesSlice.exchanges && exchangesSlice.exchanges.reduce((acc, exchange) => {
			console.log(exchange);
			const { exchangeCode, items } = exchange;
			acc[exchangeCode.toLowerCase()] = exchange;
			return acc;
		}, {})
	}
);
*/
export const selectExchangesMap = createSelector(
	[selectExchanges],
	(exchanges) => {
		console.log(exchanges);
		exchanges && exchanges.reduce((acc, exchange) => {
			console.log(exchange);
			const { exchangeCode, items } = exchange;
			acc[exchangeCode.toLowerCase()] = exchange;
			return acc;
		}, {})
	}
);

export const selectExchangesIsLoading = createSelector(
	[selectExchangeReducer],
	(exchangesSlice) => exchangesSlice.isLoading
);