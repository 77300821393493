import { USER_ACTION_TYPES } from './user-action.types';

const INITIAL_STATE = {
	currentUser: null,
}

export const userReducer = (state = INITIAL_STATE, action) => {
	console.log('userReducer rendered');
	const { type, payload } = action;

	switch (type) {
		case USER_ACTION_TYPES.SET_CURRENT_USER:
			return { ...state, currentUser: payload };
		case USER_ACTION_TYPES.GET_CURRENT_USER:
			return { currentUser: state.currentUser };
		case USER_ACTION_TYPES.GETALL_REQUEST:
			return {
				loading: true
			};
		case USER_ACTION_TYPES.GETALL_SUCCESS:
			return {
				items: action.users
			};
		case USER_ACTION_TYPES.GETALL_FAILURE:
			return {
				error: action.error
			};
		case USER_ACTION_TYPES.GET_USERPROFILE_REQUEST:
			return {
				loading: true
			};
		case USER_ACTION_TYPES.GET_USERPROFILE_SUCCESS:
			return {
				systemAccess: action.systemAccess,
				userProfile: action.userProfile
			};
		case USER_ACTION_TYPES.GET_USERPROFILE_FAILURE:
			return {
				error: action.error
			};
		case USER_ACTION_TYPES.DELETE_REQUEST:
			// add 'deleting:true' property to user being deleted
			return {
				...state,
				items: state.items.map(user =>
					user.id === action.id
						? { ...user, deleting: true }
						: user
				)
			};
		case USER_ACTION_TYPES.DELETE_SUCCESS:
			// remove deleted user from state
			return {
				items: state.items.filter(user => user.id !== action.id)
			};
		case USER_ACTION_TYPES.DELETE_FAILURE:
			// remove 'deleting:true' property and add 'deleteError:[error]' property to user 
			return {
				...state,
				items: state.items.map(user => {
					if (user.id === action.id) {
						// make copy of user without 'deleting:true' property
						const { deleting, ...userCopy } = user;
						// return copy of user with 'deleteError:[error]' property
						return { ...userCopy, deleteError: action.error };
					}

					return user;
				})
			};
		default:
			return state
	}
};