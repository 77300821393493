import { Component } from "react";
//import { connect } from 'react-redux';
import "./qbo-connect.styles.scss";
import qboSignInImage from "../images/IntuitSignIn-lg-white@2x.jpg";
//import "../../../../static/css/style.css";
import { securityActions } from '../../../../interface/actions/security.actions';

class QboSignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signInModal: false
    };

    this.showSignInWindow = this.showSignInWindow.bind(this);
  }

  showSignInWindow = async () => {
	console.log('qboSignInWindow');
	await securityActions.qboSignIn();
	  //console.log(signInUrl);
	  //this.props.dispatch(userActions.qboSignIn());
    };

  render() {
    return (
			<div>
				<p>
				<a className="imgLink" onClick={this.showSignInWindow}>
					<img
						style={{"height" : "40px"}}
						src={qboSignInImage}
					/>
				</a>
				</p>
			</div>
    );
  }
}

function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    return {
        loggingIn
    };
}


export default QboSignIn;
