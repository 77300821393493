import { securityActions } from '../../../../interface/actions/security.actions';

import AuthStateChanged from '../../../../interface/components/auth-changed.component';

const signOutHandler = async () => {
	console.log('signOutHandler');
	await securityActions.signOut();
}

const SignOut = () => {
	console.log('signing out');
	signOutHandler();

	return (
		<AuthStateChanged systemAccess='' />
	);
};


export default SignOut; 