import { all, call } from 'redux-saga/effects';

// exchange can probably just use thunk (saga may not be needed for project, can only use saga or thunk)
import { exchangesSaga } from './reducers/exchanges/exchange.saga';
//import { categoriesSaga } from './categories/category.saga';
//import { userSagas } from './user/user.saga';

export function* rootSaga() {
  yield all([call(exchangesSaga)]);
//  yield all([call(categoriesSaga), call(userSagas)]);
}