import { compose, createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
//import thunk from 'redux-thunk'; //(use saga or thunk, not both)
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import { loggerMiddleware } from './middleware/logger';

import { rootReducer } from './root-reducer';
import { rootSaga } from './root-saga';

const persistConfig = {
	key: 'root',
	storage,
	blacklist: ['jwtToken'],
	//whitelist: ['authenticationToken'],	
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

// middlewares called before reducer
//const middlewares = [logger];
const middlewares = [process.env.NODE_ENV !== 'production' && loggerMiddleware, 
//	thunk, 
	sagaMiddleware
].filter(Boolean);

const composeEnhancer = (process.env.NODE_ENV !== 'production' &&
	window &&
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
	|| compose;

const composedEnhancers = composeEnhancer(applyMiddleware(...middlewares))

// second value is for additional default states
export const store = createStore(
	persistedReducer, 
	undefined, 
	composedEnhancers
);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
