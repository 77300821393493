import { Component } from 'react';
import './card.styles.css';

// component name must start with capital letter and be camel case to be recognized by React as a created component
class Card extends Component {
	render() {
		//passed in values from <CardList myProp='' myProp2=1 />
		console.log(this.props);
		const { name, id, email } = this.props.monster;

		return (
			<div className='card-container' key={id}>
				<img
					alt={`monster ${name}`}
					src={`https://robohash.org/${id}=set2&size=180x180`}
				/>
				<h2>{name}</h2>
				<p>{email}</p>
			</div>
		);
		// you cannot have multiple parent level html components being returned from a component
		// it must have 1 parent level component
		// cannot have <div/><div/>
		// you can have <div><div/><div/></div>
	}
}

export default Card;
