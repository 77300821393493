import { EXCHANGES_ACTION_TYPES } from './exchange-action.types';

export const EXCHANGES_INITIAL_STATE = {
  exchanges: [],
  isLoading: false,
  error: null,
};

export const exchangesReducer = (
  state = EXCHANGES_INITIAL_STATE,
  action = {}
) => {
  const { type, payload } = action;

  switch (type) {
    case EXCHANGES_ACTION_TYPES.FETCH_EXCHANGES_START:
    	console.log('exchanges is loading start');
      return { ...state, isLoading: true };
    case EXCHANGES_ACTION_TYPES.FETCH_EXCHANGES_SUCCESS:
    	console.log('exchanges loaded successfully');
      return { ...state, exchanges: payload, isLoading: false };
    case EXCHANGES_ACTION_TYPES.FETCH_EXCHANGES_FAILED:
    	console.log('exchanges failed to load');
      return { ...state, error: payload, isLoading: false };
    default:
      return state;
  }
};