//import config from 'config';
import { authHeader } from '../../../components/helpers';

export const tenantService = {
    getById,
    update,
    exchangeAuth,
    basicExchangeAuth,
    qboExchangeAuth,
    squareExchangeAuth,
    oauth2Connect,
    delete: _delete
};

const tenantListEndpoint = "/api/tenants/list";
const tenantEndpoint = "/api/tenants/";
const exchangeAuthEndpoint = "/api/tenants/tenant/{tenantId}/exchangeAuth/{exchangeId}";
//const verifyExchangeAuthEndpoint = "/api/tenants/tenant/{tenantId}/verifyExchangeAuth/{exchangeId}";

var Base64 = require('js-base64').Base64;

function getAll() {
	let jwtToken = sessionStorage.getItem("jwtToken");

    const requestOptions = {
        method: 'GET',
        crossDomain:true,
        headers: {
			//...authHeader(), 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'jwtToken': `${jwtToken}`
          }
    };

    return fetch(`${process.env.REACT_APP_API}${tenantEndpoint}`, requestOptions)
    .then(handleResponse)
    .then(tenant => {
                return tenant;
    });
}

function getById(id) {
	let jwtToken = sessionStorage.getItem("jwtToken");

	const requestOptions = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'jwtToken': `${jwtToken}`
          },
        crossDomain:true,
        credentails: 'include'
    };

    return fetch(`${process.env.REACT_APP_API}${tenantEndpoint}/${id}`, requestOptions).then(handleResponse);
}

function update(tenant) {
    const requestOptions = {
        method: 'PUT',
        crossDomain:true,
        credentails: 'include',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(tenant)
    };

    return fetch(`${process.env.REACT_APP_API}/tenant/${tenant.tenantId}/update`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in
// javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        crossDomain:true,
        headers: authHeader()
    };

    return fetch(`${process.env.REACT_APP_API}/inventory/${id}`, requestOptions).then(handleResponse);
}

function exchangeAuth(callback) {
	return callback;
}

function basicExchangeAuth(tenantId, exchangeId, basicAuthUser, basicAuthPassword) {
	let jwtToken = sessionStorage.getItem("jwtToken");
    let exchangeAuthToken = 'Basic ' + Base64.encode(basicAuthUser + '::' + basicAuthPassword);

    const requestOptions = {
        method: 'POST',
        crossDomain:true,
        headers: {
			//...authHeader(), 
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'exchangeAuthToken': `${exchangeAuthToken}`,
            'jwtToken': `${jwtToken}`
          }
    };

    return fetch(`${process.env.REACT_APP_API}${exchangeAuthEndpoint}`.replace('{tenantId}', `${tenantId}`).replace('{exchangeId}', `${exchangeId}`), requestOptions)
    //return fetch(`${process.env.REACT_APP_API}${verifyExchangeAuthEndpoint}?userName=${encodeURIComponent(exchangeUsername)}&password=${encodeURIComponent(exchangePassword)}`
    //		.replace('{tenantId}', `${tenantId}`).replace('{exchangeId}', `${exchangeId}`), requestOptions)
    .then(handleResponse)
	.then(exchangeAuthSaved => {
        return exchangeAuthSaved;
    });
}

function basicExchangeAuthBackup(tenantId, exchangeId) {
    var winSignIn = window.open(`/basicExchangeAuth`, 'Exchange Authentication', 'toolbar=0,status=0,width=800,height=650');
    
	var promise = new Promise((resolve, reject) => {
    	var timer = setInterval(function() { 
        if(winSignIn.closed) {
            clearInterval(timer);
            let basicAuthUser = sessionStorage.getItem("basicAuthUser");
            let basicAuthPassword = sessionStorage.getItem("basicAuthPassword");
            let exchangeAuthToken = 'Basic ' + Base64.encode(basicAuthUser + '::' + basicAuthPassword);
            let jwtToken = sessionStorage.getItem("jwtToken");

            const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'exchangeAuthToken': `${exchangeAuthToken}`,
                        'jwtToken': `${jwtToken}`,
                        
                      },
                    crossDomain:true,
                    credentails: 'include'
                };

            return fetch(`${process.env.REACT_APP_API}${exchangeAuthEndpoint}`.replace('{tenantId}', `${tenantId}`).replace('{exchangeId}', `${exchangeId}`), requestOptions)
            .then(handleResponse)
    		.then(exchangeAuthSaved => {
                return exchangeAuthSaved;
            });
        }
    }, 1000)
    });
    
    return promise;
}

function qboExchangeAuth(tenantId, exchangeId) {
	const signInEndpoint = `/api/quickbooks/online/signInWithIntuit?use=exchange`;

    var winSignIn = window.open(`${process.env.REACT_APP_API}${signInEndpoint}`, 'Sign In With Intuit Window', 'toolbar=0,status=0');
    
	var promise = new Promise((resolve, reject) => {
    	var timer = setInterval(function() { 
        if(winSignIn.closed) {
            clearInterval(timer);
            let exchangeAuthToken = sessionStorage.getItem("lastAuthToken");
            let jwtToken = sessionStorage.getItem("jwtToken");

            const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'exchangeAuthToken': `${exchangeAuthToken}`,
                        'jwtToken': `${jwtToken}`
                      },
                    crossDomain:true,
                    credentails: 'include'
                };

            return fetch(`${process.env.REACT_APP_API}${exchangeAuthEndpoint}`.replace('{tenantId}', `${tenantId}`).replace('{exchangeId}', `${exchangeId}`), requestOptions)
            .then(handleResponse)
    		.then(exchangeAuthSaved => {
                return exchangeAuthSaved;
            });
        }
    }, 1000)
    });
    
    return promise;
}

function squareExchangeAuth(tenantId, exchangeId) {
	const signInEndpoint = `/api/square/connectToSquare?use=exchange`;

    var winSignIn = window.open(`${process.env.REACT_APP_API}${signInEndpoint}`, 'Connect to Square Window', 'toolbar=0,status=0');
    
	var promise = new Promise((resolve, reject) => {
    	var timer = setInterval(function() { 
        if(winSignIn.closed) {
            clearInterval(timer);
            let exchangeAuthToken = sessionStorage.getItem("lastAuthToken");
            let jwtToken = sessionStorage.getItem("jwtToken");

            const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'exchangeAuthToken': `${exchangeAuthToken}`,
                        'jwtToken': `${jwtToken}`
                      },
                    crossDomain:true,
                    credentails: 'include'
                };

            return fetch(`${process.env.REACT_APP_API}${exchangeAuthEndpoint}`.replace('{tenantId}', `${tenantId}`).replace('{exchangeId}', `${exchangeId}`), requestOptions)
            .then(handleResponse)
    		.then(exchangeAuthSaved => {
                return exchangeAuthSaved;
            });
        }
    }, 1000)
    });
    
    return promise;
}

function oauth2Connect(tenantId, exchangeId) {
	const connectEndpoint = `/api/oauth2/connect/{{$}exchangeId}.toLowerCase()}`;

    var winSignIn = window.open(`${process.env.REACT_APP_API}${connectEndpoint}`, 'Connect to Square Window', 'toolbar=0,status=0');
    
	var promise = new Promise((resolve, reject) => {
    	var timer = setInterval(function() { 
        if(winSignIn.closed) {
            clearInterval(timer);
            let exchangeAuthToken = sessionStorage.getItem("lastAuthToken");
            let jwtToken = sessionStorage.getItem("jwtToken");

            const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'exchangeAuthToken': `${exchangeAuthToken}`,
                        'jwtToken': `${jwtToken}`
                      },
                    crossDomain:true,
                    credentails: 'include'
                };

            return fetch(`${process.env.REACT_APP_API}${exchangeAuthEndpoint}`.replace('{tenantId}', `${tenantId}`).replace('{exchangeId}', `${exchangeId}`), requestOptions)
            .then(handleResponse)
    		.then(exchangeAuthSaved => {
                return exchangeAuthSaved;
            });
        }
    }, 1000)
    });
    
    return promise;
}

function handleResponse(response) {
    return response.json().then(json => {
        const data = json;
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
