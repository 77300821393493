import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../../../contexts/user.context';
import { TenantContext } from '../../../contexts/tenant.context';

import FormInput from '../../../components/form-input/form-input.component';
import FormInputCheckboxLeft from '../../../components/form-input/form-input-checkbox-left.component';
import Button from '../../../components/button/button.component';

import { InventoryService } from '../../../interface/services/inventory/inventory.service';
import InventorySyncResponse from './inventory-sync-response.component';

const defaultFormFields = {
	searchCategory: '',
	sourceSystem: '',
	destinationSystems: [],
	destinationFilePath: '',
	searchItem: '',
	itemsToPrint: [],
	includeActive: true,
	includeInactive: false,
	onlyAddItemsWithQuantity: false,
	startDate: '',
	endDate: '',
	submitted: false,
	loginAttempts: 0,
	syncing: false,
};

const InventorySync = () => {
	const [formFields, setFormFields] = useState(defaultFormFields);
	const [syncData, setSyncData] = useState(null);
	const [missingFields, setMissingFields] = useState(null);
	const navigate = useNavigate();

	// get the setter method for the UserProfile session storage	
	const { userProfile, setUserProfile } = useContext(UserContext);
	const { tenantProfile, setTenantProfile } = useContext(TenantContext);
	console.log(`userProfile for syncing: ${userProfile}`);

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormFields({ ...formFields, [name]: value });
	};

	const handleCategoryChange = (event) => {
		const { name, value } = event.target;
		setFormFields({ ...formFields, [name]: value });
	};

	const handleSourceSystemChange = (event) => {
		const { name, value } = event.target;
		setFormFields({ ...formFields, [name]: value });
	};

	const handleToggleCheckbox = (event) => {
		const { name, checked } = event.target;
		console.log(`handletoggleCheckbox ${name}: ${checked}`);
		setFormFields({ ...formFields, [name]: checked });
	};

	const handleSyncPricingFromCSV = async (event) => {
		event.preventDefault();

		console.log('handleSyncPricingFromCSV');
		console.log(formFields);
		setFormFields({ ...formFields, ['submitted']: true });
		setFormFields({ ...formFields, ['loggingIn']: true });
		console.log(formFields);

		if (formFields.sourceSystem && formFields.destinationSystems) {
			setMissingFields(false);

			try {
				await InventoryService.SyncPricingFromCSV(formFields.sourceSystem, formFields.destinationSystems)
					.then(response => {
						console.log('Sync Response: ', response);
					});
			}
			catch (error) {
				console.error('Inventory Pricing sync from CSV encountered an error', error);
			}
		}
		else {
			console.log('Source and Destination fields required');
			setMissingFields(true);
		}

		setFormFields({ ...formFields, ['submitted']: false });
		setFormFields({ ...formFields, ['syncing']: false });
	};

	const handleSyncSinceLast = async (event) => {
		event.preventDefault();

		console.log('handleSyncSinceLast');
		console.log(formFields);
		setFormFields({ ...formFields, ['submitted']: true });
		setFormFields({ ...formFields, ['syncing']: true });
		console.log(formFields);
		//console.log({loggingIn});

		if (formFields.sourceSystem && formFields.destinationSystems) {
			setMissingFields(false);

			try {
//				await InventoryService.SyncInventory(formFields.sourceSystem, formFields.destinationSystems, formFields.searchCategory, formFields.searchItem)
				await InventoryService.SyncInventory(formFields)
					.then(response => {
						console.log('Sync Response: ', response);
					});
			}
			catch (error) {
				console.error('Inventory sync encountered an error', error);
			}
		}
		else {
			console.log('Source and Destination fields required');
			setMissingFields(true);
		}

		setFormFields({ ...formFields, ['submitted']: false });
		setFormFields({ ...formFields, ['syncing']: false });
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		console.log('handleSubmit');
		console.log(formFields);
		setFormFields({ ...formFields, ['submitted']: true });
		setFormFields({ ...formFields, ['syncing']: true });
		console.log(formFields);
		//console.log({loggingIn});

		if (formFields.sourceSystem && formFields.destinationSystems) {
			setMissingFields(false);

			try {
//				await InventoryService.SyncInventory(formFields.sourceSystem, formFields.destinationSystems, formFields.searchCategory, formFields.searchItem)
				await InventoryService.SyncInventory(formFields)
					.then(response => {
						console.log('Sync Response: ', response);
						setSyncData(response);
						if (response.systemErrors != null && response.systemErrors.length > 0) {
							console.error('System error count: ' + response.systemErrors.length);
							console.error(response.systemErrors);
							sessionStorage.setItem('jwtToken', null);
							navigate('/system-access');
						}
					});
			}
			catch (error) {
				console.error('Inventory sync encountered an error', error);
				if (sessionStorage.getItem('jwtToken') === null || sessionStorage.getItem('jwtToken') === 'null') {
					console.log('navigating to system-access');
					navigate('/system-access');
				}
				else {
					console.log('setting jwtToken to null');
					sessionStorage.setItem('jwtToken', null);
					navigate('/system-access');
				}
			}
		}
		else {
			console.log('Source and Destination fields required');
			setMissingFields(true);
		}

		setFormFields({ ...formFields, ['submitted']: false });
		setFormFields({ ...formFields, ['syncing']: false });
	};

	return (
		<div className="page-container">
			<h3>Inventory</h3>
			{missingFields && <em>Source and Destination fields required</em>}
			{formFields.syncing && <h5>Syncing data...</h5>}
			{!formFields.syncing && syncData && syncData.hasErrors && <em>Sync contained errors</em>}
			<div>
				<form name='sync-inventory-form' onSubmit={handleSubmit}>
					<div>
						<div className='rowDisplay'>
							<FormInput
								label='Source System'
								type='text'
								onChange={handleChange}
								name='sourceSystem'
								value={formFields.sourceSystem}
							/>
							<FormInput
								label='Destination Systems'
								type='text'
								onChange={handleChange}
								name='destinationSystems'
								value={formFields.destinationSystems}
							/>
						</div>
						<div className='rowDisplay'>
							<FormInput
								label='Category'
								type='text'
								onChange={handleChange}
								name='searchCategory'
								value={formFields.searchCategory}
							/>
							<FormInput
								label='Item (name, id, sku)'
								type='text'
								onChange={handleChange}
								name='searchItem'
								value={formFields.searchItem}
							/>
						</div>
						<div className='rowDisplay'>
							<div className='form-input-column'>
								<FormInputCheckboxLeft
									label='Include Active Items'
									onClick={handleToggleCheckbox}
									name='includeActive'
									value={formFields.includeActive}
									defaultChecked={formFields.includeActive}
								/>
								<FormInputCheckboxLeft
									label='Include Inactive Items'
									onClick={handleToggleCheckbox}
									name='includeInactive'
									value={formFields.includeInactive}
									defaultChecked={formFields.includeInactive}
								/>
							</div>
							<div className='form-input-column'>
								<FormInputCheckboxLeft
									label='Only Add Items with Quantity'
									onClick={handleToggleCheckbox}
									name='onlyAddItemsWithQuantity'
									value={formFields.onlyAddItemsWithQuantity}
									defaultChecked={formFields.onlyAddItemsWithQuantity}
								/>
							</div>
						</div>
						<div>
							<span>Date Range:</span>
							<FormInput
								label='Start Date'
								type='text'
								onChange={handleChange}
								name='startDate'
								value={formFields.startDate}
							/>
							<FormInput
								label='End Date'
								type='text'
								onChange={handleChange}
								name='endDate'
								value={formFields.endDate}
							/>
						</div>
					</div>
					<div className='buttons-container'>
						<Button type='submit' disabled={formFields.syncing && true}>Sync All</Button>
						<Button type='Button' disabled={formFields.syncing && true} onClick={handleSyncSinceLast}>Sync Since Last Import</Button>
						<Button type='Button' disabled={formFields.syncing && true} onClick={handleSyncPricingFromCSV}>Sync Prices From CSV</Button>
					</div>
				</form>
			</div>
			{!formFields.syncing && syncData && syncData.hasErrors && <em>Sync contained errors</em>}
			{!formFields.syncing && syncData && syncData.destinationSyncList && syncData.destinationSyncList.length > 0 && <InventorySyncResponse syncData={syncData} />}
			{!formFields.syncing && syncData && syncData.destinationSyncList && syncData.destinationSyncList.length == 0 && <h5>No data to sync</h5>}
		</div>
	)
}

export default InventorySync;