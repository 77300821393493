const connectExchangeOAuth2 = (tenantId, exchangePartner, callback) => {
	const { exchangeCode, exchangeName } = exchangePartner;

	const connectOAuth2Endpoint = `/api/oauth2/connect-exchange/${exchangeCode}/${tenantId}`;

	var winConnect = window.open(`${process.env.REACT_APP_API}${connectOAuth2Endpoint}`, `Connect To ${exchangeName} Window`, 'toolbar=0,status=0,width=800,height=650');

	var promise = new Promise((resolve, reject) => {
		var timer = setInterval(function() {
			if (winConnect.closed) {
				clearInterval(timer);
				return;
			}
		}, 1000)
	});

	return promise;
}

const revokeExchangeOAuth2 = async(tenantId, exchangePartner, callback) => {
	const { exchangeCode, exchangeName } = exchangePartner;

	const revokeOAuth2Endpoint = `/api/oauth2/disconnect-exchange/${exchangeCode.toLowerCase()}/${tenantId}/`;

	let jwtToken = sessionStorage.getItem("jwtToken");
	console.log(`revoke jwt token: ${jwtToken}`);

	const requestOptions = {
		method: 'GET',
		headers: {
			'Origin': `${process.env.APP_ORIGIN}`,
			'X-CSRF-Token': 'Fetch',
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Request-Method': 'GET',
			'Authorization': `${jwtToken}`,
			'jwtToken': `${jwtToken}`
		},
		crossDomain: true,
		credentails: 'include'
	};

	console.log(requestOptions);
	return fetch(`${process.env.REACT_APP_API}${revokeOAuth2Endpoint}`, requestOptions)
		.then(handleResponse);
}

const revokeExchangeOAuth2Test = (tenantId, exchangePartner, callback) => {
	const { exchangeCode, exchangeName } = exchangePartner;

	const revokeOAuth2Endpoint = `/api/oauth2/disconnect-exchange/${exchangeCode}/${tenantId}`;

	var winConnect = window.open(`${process.env.REACT_APP_API}${revokeOAuth2Endpoint}`, `Disconnecting From ${exchangeName} Window`, 'toolbar=0,status=0,width=800,height=650');

	var promise = new Promise((resolve, reject) => {
		var timer = setInterval(function() {
			if (winConnect.closed) {
				clearInterval(timer);
				return;
			}
		}, 1000)
	});

	return promise;
//	return;
}

const connectExchangeQboAuth = (exchangePartner, callback) => {

}

const connectExchangeBasicAuth = (exchangePartner, callback) => {

}

const getAllEndpoint = "/api/tenant/";

const getAll = async () => {
	let jwtToken = sessionStorage.getItem("jwtToken");

	console.log(jwtToken);
	const requestOptions = {
		method: 'GET',
		headers: {
			'Origin': `${process.env.APP_ORIGIN}`,
			'X-CSRF-Token': 'Fetch',
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Request-Method': 'GET',
			'Authorization': `${jwtToken.jwtToken}`,
			'jwtToken': `${jwtToken.jwtToken}`
		},
		crossDomain: true,
		credentails: 'include',
	};

	console.log(requestOptions);
	return fetch(`${process.env.REACT_APP_API}${getAllEndpoint}`, requestOptions)
		.then(handleResponse)
		.then(exchangePartners => {
			console.log(exchangePartners);
			return exchangePartners;
		});
}

const handleResponse = async (response) => {
	console.log('service handle response');
	console.log({ response });
	return response.json().then(json => {
		const data = json;

		if (!response.ok) {
			const error = (data && data.message) || response.statusText;
			return Promise.reject(error);
		}

		return data;
	});
}

export const receiveTenantMessage = (event) => {
	// Do we trust the sender of this message?  (might be
	// different from what we originally opened, for example).
	//  if (event.origin !== "http://localhost:9080")
	//    return;

	if (typeof event.data === "string" && event.data.indexOf("Bearer ") === 0) {
		sessionStorage.setItem('lastAuthToken', event.data)
	}
	// event.source is popup
	// event.data is "hi there yourself! the secret response is: rheeeeet!"
}

window.addEventListener("message", receiveTenantMessage, false);

export const TenantService = {
	connectExchangeOAuth2,
	revokeExchangeOAuth2,
	connectExchangeQboAuth,
	connectExchangeBasicAuth,
};
