import {createContext, useState, useEffect} from 'react';
import {SystemAccessService} from '../interface/services/system-access/system-access.service';

// actual value you want to access
export const TenantContext = createContext({
	tenantProfile: null, 
	setTenantProfile: () => null,	
})

// component
export const TenantProvider = ({ children }) => {
	const [tenantProfile, setTenantProfile] = useState(null);
	const value = {tenantProfile, setTenantProfile};
	
	return <TenantContext.Provider value={value}>{children}</TenantContext.Provider>;
}