import { useContext } from 'react';
import { SystemAccessService } from '../services/system-access/system-access.service'
import { UserContext } from '../../contexts/user.context';
import { TenantContext } from '../../contexts/tenant.context';
/*
const AuthStateChanged = (systemAccess) => {
	console.log('security-actions.AuthStateChanged');
	const { setUserProfile } = useContext(UserContext);
	const { setTenantProfile } = useContext(TenantContext);
	
	// set context which should update rest of UI
	setUserProfile(systemAccess ? systemAccess.userProfile : null);
	setTenantProfile(systemAccess ? systemAccess.tenantProfile : null);
}

const signOut = async () => {
	await SystemAccessService.SignOut();
	return null;
}
*/
const authenticateBasicUser = async (username, password) => {
	try {
		const response = await SystemAccessService.AuthenticateBasic(username, password);
		console.log('authenticateBasicUser', response);
		//AuthStateChanged(response);
		return response;
	}
	catch (error) {
		console.error('authenticateBasicUser', error);
	}
}

const onboardNewTenant = async (onboardRequest) => {
	try {
		const response = await SystemAccessService.onboardTenant(onboardRequest);
		console.log('onboardTenant', response);
		return response;
	}
	catch (error) {
		console.error('onboardTenant', error);
		throw error;
	}
}

const qboConnect = async () => {
	return (
//		dispatch(request( 'QBO' ));
		
		await SystemAccessService.qboConnect()
			.then(
				systemAccess => { 
//					dispatch(success(systemAccess));
//					history.push('/');
				},
				error => {
//					dispatch(failure(error.toString()));
//					dispatch(alertActions.error(error.toString()));
				}
			)
	);
};

const qboSignIn = async () => {
	return (
//		dispatch(request( 'QBO' ));
		
		await SystemAccessService.qboSignIn()
			.then(
				systemAccess => { 
//					dispatch(success(systemAccess));
//					history.push('/');
				},
				error => {
//					dispatch(failure(error.toString()));
//					dispatch(alertActions.error(error.toString()));
				}
			)
	);
}

const deleteUser = async (id) => {
	return (
//		dispatch(request(id));

		await SystemAccessService.deleteUser(id)
			.then(
//				user => dispatch(success(id)),
//				error => dispatch(failure(id, error.toString()))
			)
	);
}

export const securityActions = {
    //signOut,
    authenticateBasicUser,
    onboardNewTenant,
    qboConnect,
    qboSignIn,
    deleteUser,
//    getAll,
//    deleteUser: _delete
};
