import { createContext, useState, useEffect } from 'react';

const addInventoryItem = (inventoryItems, productToAdd) => {
  const existingInventoryItem = inventoryItems.find(
    (inventoryItem) => inventoryItem.id === productToAdd.id
  );

  if (existingInventoryItem) {
    return inventoryItems.map((inventoryItem) =>
      inventoryItem.id === productToAdd.id
        ? { ...inventoryItem, quantity: inventoryItem.quantity + 1 }
        : inventoryItem
    );
  }

  return [...inventoryItems, { ...productToAdd, quantity: 1 }];
};

const removeInventoryItem = (inventoryItems, inventoryItemToRemove) => {
  // find the inventory item to remove
  const existingInventoryItem = inventoryItems.find(
    (inventoryItem) => inventoryItem.id === inventoryItemToRemove.id
  );

  // check if quantity is equal to 1, if it is remove that item from the inventory
  if (existingInventoryItem.quantity === 1) {
    return inventoryItems.filter((inventoryItem) => inventoryItem.id !== inventoryItemToRemove.id);
  }

  // return back inventoryitems with matching inventory item with reduced quantity
  return inventoryItems.map((inventoryItem) =>
    inventoryItem.id === inventoryItemToRemove.id
      ? { ...inventoryItem, quantity: inventoryItem.quantity - 1 }
      : inventoryItem
  );
};

const clearInventoryItem = (inventoryItems, inventoryItemToClear) =>
  inventoryItems.filter((inventoryItem) => inventoryItem.id !== inventoryItemToClear.id);

export const InventoryContext = createContext({
  isInventoryOpen: false,
  setIsInventoryOpen: () => {},
  inventoryItems: [],
  addItemToInventory: () => {},
  removeItemFromInventory: () => {},
  clearItemFromInventory: () => {},
  inventoryCount: 0,
  inventoryTotal: 0,
});

export const InventoryProvider = ({ children }) => {
  const [isInventoryOpen, setIsInventoryOpen] = useState(false);
  const [inventoryItems, setInventoryItems] = useState([]);
  const [inventoryCount, setInventoryCount] = useState(0);
  const [inventoryTotal, setInventoryTotal] = useState(0);

  useEffect(() => {
    const newInventoryCount = inventoryItems.reduce(
      (total, inventoryItem) => total + inventoryItem.quantity,
      0
    );
    setInventoryCount(newInventoryCount);
  }, [inventoryItems]);

  useEffect(() => {
    const newInventoryTotal = inventoryItems.reduce(
      (total, inventoryItem) => total + inventoryItem.quantity * inventoryItem.price,
      0
    );
    setInventoryTotal(newInventoryTotal);
  }, [inventoryItems]);

  const addItemToInventory = (productToAdd) => {
    setInventoryItems(addInventoryItem(inventoryItems, productToAdd));
  };

  const removeItemToInventory = (inventoryItemToRemove) => {
    setInventoryItems(removeInventoryItem(inventoryItems, inventoryItemToRemove));
  };

  const clearItemFromInventory = (inventoryItemToClear) => {
    setInventoryItems(clearInventoryItem(inventoryItems, inventoryItemToClear));
  };

  const value = {
    isInventoryOpen,
    setIsInventoryOpen,
    addItemToInventory,
    removeItemToInventory,
    clearItemFromInventory,
    inventoryItems,
    inventoryCount,
    inventoryTotal,
  };

  return <InventoryContext.Provider value={value}>{children}</InventoryContext.Provider>;
};
