import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../../../contexts/user.context';
import { TenantContext } from '../../../contexts/tenant.context';

import FormInput from '../../../components/form-input/form-input.component';
import Button from '../../../components/button/button.component';

import { InventoryService } from '../../../interface/services/inventory/inventory.service';

const defaultFormFields = {
	searchCategory: undefined,
	sourceSystem: undefined,
	destinationSystems: [],
	searchItem: undefined,
	itemsToPrint: [],
	submitted: false,
	loginAttempts: 0,
	syncing: false,
};

const Inventory = () => {
	const [formFields, setFormFields] = useState(defaultFormFields);
	const [syncData, setSyncData] = useState(null);
	const navigate = useNavigate();

	// get the setter method for the UserProfile session storage	
	const { userProfile, setUserProfile } = useContext(UserContext);
	const { tenantProfile, setTenantProfile } = useContext(TenantContext);
	console.log(`userProfile for syncing: ${userProfile}`);

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormFields({ ...formFields, [name]: value });
	};

	const handleCategoryChange = (event) => {
		const { name, value } = event.target;
		setFormFields({ ...formFields, [name]: value });
	};

	const handleSourceSystemChange = (event) => {
		const { name, value } = event.target;
		setFormFields({ ...formFields, [name]: value });
	};

	const handleSyncPricingFromCSV = async (event) => {
		event.preventDefault();

		console.log('handleSyncPricingFromCSV');
		console.log(formFields);
		setFormFields({ ...formFields, ['submitted']: true });
		setFormFields({ ...formFields, ['syncing']: true });
		console.log(formFields);

		if (formFields.sourceSystem && formFields.destinationSystems) {
			try {
				await InventoryService.SyncPricingFromCSV(formFields.sourceSystem, formFields.destinationSystems)
					.then(response => {
						console.log('Sync Response: ', response);
					});
			}
			catch (error) {
				console.error('Inventory Pricing sync from CSV encountered an error', error);
			}
		}

		setFormFields({ ...formFields, ['submitted']: false });
		setFormFields({ ...formFields, ['syncing']: false });
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		console.log('handleSubmit');
		console.log(formFields);
		setFormFields({ ...formFields, ['submitted']: true });
		setFormFields({ ...formFields, ['syncing']: true });
		console.log(formFields);
		//console.log({loggingIn});

		if (formFields.sourceSystem && formFields.destinationSystems) {
			try {
				await InventoryService.SyncInventory(formFields.sourceSystem, formFields.destinationSystems, formFields.searchCategory, formFields.searchItem)
					.then(response => {
						console.log('Sync Response: ', response);
						setSyncData(response);
						if (response.systemErrors.length > 0) {
							console.error('System error count: ' + response.systemErrors.length);
							console.error(response.systemErrors);
							sessionStorage.setItem('jwtToken', null);
							navigate('/system-access');
						}
					});
			}
			catch (error) {
				console.error('Inventory sync encountered an error', error);
			}
		}

		setFormFields({ ...formFields, ['submitted']: false });
		setFormFields({ ...formFields, ['syncing']: false });
	};

	return (
		<div className="page-container">
			<h3>Inventory</h3>
			<div>
				<form name='sync-inventory-form' onSubmit={handleSubmit}>
					<div>
						<FormInput
							label='Category'
							type='text'
							onChange={handleChange}
							name='searchCategory'
							value={formFields.searchCategory}
						/>
						<FormInput
							label='Source System'
							type='text'
							onChange={handleChange}
							name='sourceSystem'
							value={formFields.sourceSystem}
						/>
						<FormInput
							label='Destination Systems'
							type='text'
							onChange={handleChange}
							name='destinationSystems'
							value={formFields.destinationSystems}
						/>
						<FormInput
							label='Item (name, id, sku)'
							type='text'
							onChange={handleChange}
							name='searchItem'
							value={formFields.searchItem}
						/>
					</div>
					<div className='buttons-container'>
						<Button type='submit'>Sync</Button>
						<Button type='Button' onClick={handleSyncPricingFromCSV}>Sync Prices From CSV</Button>
					</div>
				</form>
			</div>
			{formFields.syncing && <h3>Syncing data...</h3>}
			{!formFields.syncing && syncData && syncData.hasErrors && <h4>Sync contained errors</h4>}
		</div>
	)
}

export default Inventory;