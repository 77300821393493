import { useEffect, useState } from 'react';
//import { connect } from 'react-redux';
//import { useDispatch } from "react-redux";
import QboSignIn from '../../components/qbo/qbo-signin.component';
import QboConnect from '../../components/qbo/qbo-connect.component';

import { defaultOnboardRequest } from './constants/onboard.constants'
import SignUpForm from './components/sign-up-form.component';

//import { userActions } from '../../../../actions';
import { securityActions } from '../../../../interface/actions/security.actions';
import { useHistory } from 'react-router-dom';

import './components/sign-up.styles.scss';
//import styled from 'styled-components';

const SignUp = () => {
	const [onboardRequest, setOnboardRequest] = useState(defaultOnboardRequest);
	const { systemAccess, tenantProfile } = onboardRequest;
	const { submitted } = onboardRequest;
	//console.log(systemAccess);

	//	const dispatch = useDispatch();

	const resetFormFields = () => {
		setOnboardRequest(defaultOnboardRequest);
	};
	/*
		const getComplexName = (nameArray, parentObject) => {
			//...onboardRequest,
			//		userProfile: {
			//			...onboardRequest.userProfile,
			//				contact: {
			//					...onboardRequest.userProfile.contact,
			//					email: value 
			//				}
			// or
			//				firstName: value
			//			}
	
			if (nameArray.length > 1) {
				console.log(nameArray[0]);
				console.log(parentObject);
				const newParentObject = {...parentObject}.nameArray[0];
				console.log({newParentObject});
				return;
	
				const currentParent = nameArray[0];
				const childObject = getComplexName(nameArray.shift(), newParentObject);
				const complexObject = { 
					[currentParent]: {
						newParentObject,
						childObject	
					}
				}
	
				console.log(complexObject);
				return complexObject;
			}
			else {
				console.log(`[nameArray[0]]`);
				return `[${nameArray[0]}]`;
			};
		};
	*/
	const handleChange = (event) => {
		const { name, value } = event.target;
		//		console.log('Handle Change');
		//		console.log(name);
		//		console.log(value);
		//		console.log(onboardRequest);

		const nameArray = name.split(".");
		if ('systemAccess' === nameArray[0]) {
			if ('userProfile' === nameArray[1]) {
				if ('contact' === nameArray[2]) {
					setOnboardRequest(onboardRequest => ({
						...onboardRequest, ['systemAccess']:
						{
							...onboardRequest.systemAccess, ['userProfile']:
							{
								...onboardRequest.systemAccess.userProfile, ['contact']:
									{ ...onboardRequest.systemAccess.userProfile.contact, [nameArray[3]]: value }
							}
						}
					}));
				}
				else {
					setOnboardRequest(onboardRequest => ({
						...onboardRequest, ['systemAccess']:
						{
							...onboardRequest.systemAccess, ['userProfile']:
								{ ...onboardRequest.systemAccess.userProfile, [nameArray[2]]: value }
						}
					}));
				}
				//			console.log(onboardRequest);
				return;
			}
			else {
				setOnboardRequest(onboardRequest => ({
					...onboardRequest, ['systemAccess']:
					{
						...onboardRequest.systemAccess, [nameArray[1]]: value
					}
				}));
				return;
			}
		}

		if ('tenantProfile' === nameArray[0]) {
			if ('contact' === nameArray[1]) {
				setOnboardRequest(onboardRequest => ({
					...onboardRequest, ['tenantProfile']:
					{
						...onboardRequest.tenantProfile, ['contact']:
							{ ...onboardRequest.tenantProfile.contact, [nameArray[2]]: value }
					}
				}));
			}
			else if ('exchangePartners' === nameArray[1]) {
				console.log(`exchangePartners changed`);
				setOnboardRequest(onboardRequest => ({
					...onboardRequest, ['exchangePartners']:
						{ ...onboardRequest.exchangePartners, value }
				}));
				console.log(onboardRequest);
				return;
			}
			else {
				setOnboardRequest(onboardRequest => ({
					...onboardRequest, ['tenantProfile']:
						{ ...onboardRequest.tenantProfile, [nameArray[1]]: value }
				}));
			}
			//console.log(onboardRequest);
			return;
		}

		if ('account' === nameArray[0]) {
			setOnboardRequest(onboardRequest => ({
				...onboardRequest, ['account']:
					{ ...onboardRequest.account, [nameArray[1]]: value }
			}));
			console.log(onboardRequest);
			return;
		}
		//		console.log(getComplexName(nameArray, { ...onboardRequest }));

		//		console.log(`${name}: ${value}: ${onboardRequest.userProfile.firstName}`);
		//		setOnboardRequest(onboardRequest => ({ ...onboardRequest, [getComplexName(nameArray, onboardRequest)]: value }));
		setOnboardRequest(onboardRequest => ({ ...onboardRequest, [name]: value }));
		//		console.log(onboardRequest);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setOnboardRequest(onboardRequest => ({ ...onboardRequest, ['submitted']: true }));

		console.log(onboardRequest);  // user {} to log JSON object

		if (!onboardRequest.systemAccess.userName && !onboardRequest.systemAccess.password && !onboardRequest.systemAccess.systemAccess.userProfile.firstName && !onboardRequest.systemAccess.userProfile.lastName) {
			alert('Required values are missing');
			setOnboardRequest(onboardRequest => ({ ...onboardRequest, ['submitted']: false }));
			return;
		}

		if (onboardRequest.systemAccess.password !== onboardRequest.systemAccess.confirmPassword) {
			alert("Passwords do not match");
			setOnboardRequest(onboardRequest => ({ ...onboardRequest, ['submitted']: false }));
			return;
		}

		try {
			console.log("onboardTenant now")
			//const response = dispatch(onboardTenant(onboardRequest));
			const response = await securityActions.onboardNewTenant(onboardRequest);
			console.log(response);
			setOnboardRequest(onboardRequest => ({ ...onboardRequest, ['submitted']: false }));
			//resetFormFields();
		}
		catch (error) {
			setOnboardRequest(onboardRequest => ({ ...onboardRequest, ['submitted']: false }));
			console.error('Onboarding encountered an error', error);
			alert(error);
		}

	};

	// use for redirect back from provider (QBO, ECWID, etc) as the re-render occurs when we come back to page
	//useEffect(async () => {
	//	const response = await getQboCallback(auth);  // auth is singleton for the app commconx-api.component
	//	if (response) {
	//		const userRefDoc = await createUserFromAuth(response.user);
	//	}
	//}, []); // empty array means run the effect once

	//const logQboUser = async () => {
	//	const response = await signInWithQboPopup();
	//	console.log(response);
	//} 

	return (
		<div className="sign-up-container" >
			<h2>Don't have an account? - Get Connected!!</h2>
			<div><span>Sign Up using ERP:</span> <QboConnect /></div>
			<div><SignUpForm onboardRequest={onboardRequest} onChangeHandler={handleChange} onSubmitHandler={handleSubmit} /></div>
		</div>
	);
};

export default SignUp; 