import React from 'react';
import './modal.css';

const BasicAuthModal = ({ handleSaveModal, handleCancelModal, showModal, children }) => {
  const showHideClassName = showModal ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        {children}
        <button type="button" onClick={handleSaveModal}>
        Save
      </button>
        <button type="button" onClick={handleCancelModal}>
          Cancel
        </button>
      </section>
    </div>
  );
};

export default BasicAuthModal