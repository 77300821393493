import React from 'react';
import { useState } from 'react';
import { useTable, useRowSelect } from 'react-table';

import Button from '../button/button.component';

import './data-table.styles.scss';

const DataTable = ({ columns, data, selectedDataRows, handleSelected, ...otherProps }) => {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		selectedFlatRows,
		state: { selectedRowIds },
	} = useTable({
		columns,
		data,
	},
		useRowSelect
	);

	const [selectedRows, setSelectedRows] = useState(selectedDataRows);

	const handleCheckboxClicked = async (row) => {
		console.log('initial selected Rows: ' + JSON.stringify(selectedRows));
		// Toggle the checkbox for the clicked row
		console.log('handleCheckboxClicked');
		console.log(row.isSelected);
		await row.toggleRowSelected();
		console.log(row.isSelected);
		if (row.isSelected) {
//			setSelectedRows([...selectedRows, row.original]);
		}
//		console.log(selectedFlatRows);
//		console.log(selectedRowIds);
		setSelectedRows([rows.filter((r) => r.isSelected).map((r) => r.original)]);
//		console.log(selectedRows);
	};

	const processSelected = async (event) => {
		//rows.filter((r) => r.isSelected).forEach(r => r.selectedCustomer = r.original.)
		setSelectedRows([rows.filter((r) => r.isSelected).map((r) => r.original)]);
		handleSelected(event, selectedRows);
		setSelectedRows([]);
	};
			
return (
		<React.Fragment>
			<Button className="button-secondary" type='Button' onClick={processSelected}>Sync checked sales</Button>

			<table {...getTableProps()} style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid black' }}>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th
									{...column.getHeaderProps({
										style: { minWidth: column.minWidth, width: column.width },
									})}
									style={{ borderBottom: '2px solid black', background: 'aliceblue', padding: '8px', border: '1px solid black', textAlign: "center" }}
								>
									<span>{column.render('Header')}</span>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row, i) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()} style={{ borderBottom: '1px solid black', padding: '8px', border: '1px solid black' }}>
								{row.cells.map((cell) => (
									<td {...cell.getCellProps()}>
										{/* Render a checkbox for the first column based on if type is NEEDS_EMPLOYEE */}
										{/*cell.column.id === 'selection' && row.cells.find('syncState') && row.cells.find('syncState').value != 'NEEDS_CUSTOMER' ? (*/}
										{cell.column.id === 'selection' ? (
											<input
												type="checkbox"
												{...row.getToggleRowSelectedProps()}
												onClick={() => handleCheckboxClicked(row)}
												//onChange={() => handleCheckboxChange(row)}
												disabled={row.values.syncState === 'NEEDS_CUSTOMER' ? undefined : true}
											/>
										) : (
											cell.render('Cell')
										)}
									</td>
								))}
							</tr>
						);
					})}
				</tbody>
			</table>
		</React.Fragment>
	);
};

export default DataTable;