import { useContext } from 'react';

import { UserContext } from '../../../../contexts/user.context';

import SignIn from '../../scenes/SignInPage/sign-in.component';
import SignUp from '../../scenes/SignUpPage/sign-up.component';
import Dashboard from '../../../Dashboard/dashboard.component';

import './authentication.styles.scss';

const Authentication = () => {
	const { userProfile } = useContext(UserContext);
	console.log(`authentication userProfile: ${userProfile}`);
	
	if (userProfile != null) {
		return (<Dashboard />);
	}

	return (
		<div className='authentication-container'>
			<SignIn />
			<SignUp  />
		</div>
	);
};

export default Authentication;