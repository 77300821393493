import { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";

import Button from '../../../../components/button/button.component';

const NewInventory = () => {
	return(
		<ul><li>Item 1</li><li>Item 2</li></ul>
	)
}

const defaultFormFields = {
	sourceSystem: '',
	submitted: false,
	syncing: false,
};

const BarcodesWidget = () => {
  let navigate = useNavigate(); 
  const printBarcodeRouteChange = () =>{ 
    let path = `print-barcodes`; 
	console.log('changing barcode route to ' + path);
    navigate(path);
  }

	return(
		<div className='widget-details'>
			New Items
			<div className="new-items">
				<NewInventory/>
			</div>
			<Button onClick={printBarcodeRouteChange}>Barcode Printing</Button>
		</div>
	)
}

export default BarcodesWidget;