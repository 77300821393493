import './form-input.styles.scss';

const FormInputCheckboxLeft = ({ label, ...otherProps }) => {
//	console.log(otherProps);
	return (
		<div className='form-input-checkbox-container'>
			<input className='form-input-checkbox' type='checkbox' {...otherProps} />
			{label && (
				<label
					className='form-input-checkbox-label'>
					{label}
				</label>
			)}
		</div>
	);
};

export default FormInputCheckboxLeft;