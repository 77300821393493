import React from 'react';
import { useState } from 'react';
import Button from '../../../components/button/button.component';

import SalesSyncDestinationResponse from './sales-sync-destination-response.component';

const SalesSyncResponse = ({ syncData, handleSyncSelected }, parentFormFields ) => {
	console.log('resetting sales-sync-response');
	const [selectedRows, setSelectedRows] = useState([]);
//	const [formFields, setFormFields] = useState(parentFormFields);

//	const handleSyncSelected = async (event, selectedDataRows) => {
//		event.preventDefault();

//		console.log('handleSyncSelected in sales-sync-response');
//		//console.log(selectedRows);  // always empty, no need to pass in
//		console.log(selectedDataRows);
//		console.log(formFields);
//		setFormFields({ ...formFields, 'submitted': true });
//		setFormFields({ ...formFields, 'syncing': true });
//		console.log(formFields);
//		//console.log({loggingIn});

//		if (formFields.sourceSystem && formFields.destinationSystems) {
//			try {
//				await SalesService.SyncOrders(formFields)
//					.then(response => {
//						console.log('Sync Response: ', response);
//					});
//			}
//			catch (error) {
//				console.error('Sales sync encountered an error', error);
//				if (sessionStorage.getItem('jwtToken') === null) {
//					navigate('/system-access');
//				}
//			}
//		}
//		else {
//			console.log('Source and Destination fields required');
//			setMissingFields(true);
//		}

//		setFormFields({ ...formFields, 'submitted': false });
//		setFormFields({ ...formFields, 'syncing': false });
//		setSelectedRows([]);
//	};

	return (
		<div className="sales-response-container">
			{syncData.destinationSyncList.map(destinationSync => (
				<div className="container" key={destinationSync.destinationSystem}>
					<div><span>{syncData.sourceSystem} to {destinationSync.destinationSystem} Results</span></div>
					<SalesSyncDestinationResponse syncResults={destinationSync} selectedSalesRows={selectedRows} handleSalesSelected={handleSyncSelected}/>
				</div>
			))}
		</div>
	);
};

export default SalesSyncResponse;