import { TENANT_ACTION_TYPES } from './tenant-action.types';

const INITIAL_STATE = {
	currentTenant: null,
}

export const tenantReducer = (state = INITIAL_STATE, action) => {
	const { type, payload } = action;
	console.log(`tenantReducer rendered - ${type}`);

	switch (type) {
		case TENANT_ACTION_TYPES.FETCH_TENANT_START:
			console.log('tenant is loading start');
			return { ...state, isLoading: true };
		case TENANT_ACTION_TYPES.FETCH_TENANT_SUCCESS:
			console.log('tenant loaded successfully');
			return { ...state, currentTenant: payload, isLoading: false };
		case TENANT_ACTION_TYPES.FETCH_TENANT_FAILED:
			console.log('tenant failed to load');
			return { ...state, error: payload, isLoading: false };
		case TENANT_ACTION_TYPES.SET_CURRENT_TENANT:
			return { ...state, currentTenant: payload };
		case TENANT_ACTION_TYPES.GETALL_REQUEST:
			return {
				loading: true
			};
		case TENANT_ACTION_TYPES.GETALL_SUCCESS:
			return {
				items: action.users
			};
		case TENANT_ACTION_TYPES.GETALL_FAILURE:
			return {
				error: action.error
			};
		case TENANT_ACTION_TYPES.GET_USERPROFILE_REQUEST:
			return {
				loading: true
			};
		case TENANT_ACTION_TYPES.GET_USERPROFILE_SUCCESS:
			return {
				systemAccess: action.systemAccess,
				userProfile: action.userProfile
			};
		case TENANT_ACTION_TYPES.GET_USERPROFILE_FAILURE:
			return {
				error: action.error
			};
		case TENANT_ACTION_TYPES.DELETE_REQUEST:
			// add 'deleting:true' property to user being deleted
			return {
				...state,
				items: state.items.map(user =>
					user.id === action.id
						? { ...user, deleting: true }
						: user
				)
			};
		case TENANT_ACTION_TYPES.DELETE_SUCCESS:
			// remove deleted user from state
			return {
				items: state.items.filter(user => user.id !== action.id)
			};
		case TENANT_ACTION_TYPES.DELETE_FAILURE:
			// remove 'deleting:true' property and add 'deleteError:[error]' property to user 
			return {
				...state,
				items: state.items.map(user => {
					if (user.id === action.id) {
						// make copy of user without 'deleting:true' property
						const { deleting, ...userCopy } = user;
						// return copy of user with 'deleteError:[error]' property
						return { ...userCopy, deleteError: action.error };
					}

					return user;
				})
			};
		default:
			return state
	}
};