import { createAction } from '../reducer.util';
import { EXCHANGES_ACTION_TYPES } from './exchange-action.types';

export const fetchExchangesStart = () =>
	createAction(EXCHANGES_ACTION_TYPES.FETCH_EXCHANGES_START);

export const fetchExchangesSuccess = (exchangesArray) =>
	createAction(EXCHANGES_ACTION_TYPES.FETCH_EXCHANGES_SUCCESS, exchangesArray);

export const fetchExchangesFailed = (error) =>
	createAction(EXCHANGES_ACTION_TYPES.FETCH_EXCHANGES_FAILED, error);
	
// fetchExchangesAsync = () => {} should wrap the above functions