import styled from 'styled-components';

export const ExchangePartnersContainer = styled.div`
  width: 55%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto 0;
`;

export const ExchangePartnersHeader = styled.div`
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid darkgrey;
`;

export const HeaderBlock = styled.div`
  text-transform: capitalize;
  width: 23%;
  &:last-child {
    width: 8%;
  }
`;

export const ExchangePartnerContainer = styled.div`
  width: 100%;
  display: flex;
  min-height: 100px;
  border-bottom: 1px solid darkgrey;
  padding: 15px 0;
  font-size: 12px;
  align-items: center;
`;

export const BaseSpan = styled.span`
  width: 23%;
`;

export const FlagSpan = styled.span`
  width: 23%;
  padding-left: 5px;
`;

export const AuthButton = styled.div`
  padding-left: 12px;
  cursor: pointer;
`;