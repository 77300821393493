import { ExchangePartnerService } from '../services/exchange-partner/exchange-partner.service'

const getAvailableExchangePartners = async () => {
	try {
		const response = await ExchangePartnerService.getAll();
		return response;
	}
	catch (error) {
		console.error('getAvailableExchangePartners', error);
	}
}

export const exchangePartnerActions = {
    getAvailableExchangePartners,
};
