import React from 'react';
import { useState, useMemo } from 'react';

import DataTable from '../../../components/table/data-table.component';
import DisplaySyncCustomer from './display-sync-customer';
import currency from 'currency.js';

const SalesSyncDestinationResponse = ({ syncResults, selectedSalesRows, handleSalesSelected }) => {
	const handleSelectedCustomer = async(saleOrderNumber, selectedCustomer) => {
		console.log('useCustomer function')
		console.log(syncResults);
		console.log(selectedCustomer);
		syncResults.objectSyncState.filter(r => r.saleOrderNumber === saleOrderNumber).forEach(r => {
			// Not currently using minimized version
			const customerDetails = {};
			customerDetails.name = selectedCustomer.displayName;
			if (selectedCustomer.primaryEmailAddr !== undefined && selectedCustomer.primaryEmailAddr !== null) {
				customerDetails.email = selectedCustomer.primaryEmailAddr;
			}
			if (selectedCustomer.primaryPhone !== undefined && selectedCustomer.primaryPhone !== null) {
				customerDetails.phone = selectedCustomer.primaryPhone.freeFormNumber;
			}
			customerDetails.address = selectedCustomer.billAddr;
			

			console.log(customerDetails);
			r.useCustomer = selectedCustomer
		});
		console.log(syncResults);
	}

	const columns = React.useMemo(
		() => [
			{
				Header: 'Select',
				accessor: 'selection', // This accessor is used for the checkbox column
			},
			{
				Header: "Order #",
				accessor: "saleOrderNumber",
				maxWidth: 75,
				minWidth: 50,
				width: 75,
				textAlign: "left",
				borderWidth: "2px",
				borderStyle: "solid",
			},
			{
				Header: "Timestamp",
				accessor: "saleTimestamp",
				maxWidth: 100,
				minWidth: 50,
				width: 100,
				textAlign: "left",
				borderWidth: "2px",
				borderStyle: "solid",
			},
			{
				Header: "Sync State",
				accessor: "syncState",
				maxWidth: 100,
				minWidth: 50,
				width: 100,
				textAlign: "left",
				borderWidth: "2px",
				borderStyle: "solid",
			},
			{
				Header: "Customer",
				accessor: ({ customerDtos, syncState, saleOrderNumber }) => (
					<DisplaySyncCustomer customerList={customerDtos} syncState={syncState} saleOrderNumber={saleOrderNumber} handleSelectedCustomer={handleSelectedCustomer} />
				),
				maxWidth: 200,
				minWidth: 50,
				width: 200,
				textAlign: "right",
				//Cell: (row, props) => <DisplaySyncCustomer customerList={props.value} synced={row.original.syncState}/>,
				//Cell: ({ value }) => currency(value),
				borderWidth: "2px",
				borderStyle: "solid",
			},
			{
				Header: "Items",
				accessor: "itemCount",
				maxWidth: 75,
				minWidth: 50,
				width: 75,
				textAlign: "right",
				borderWidth: "2px",
				borderStyle: "solid",
			},
			{
				Header: "Subtotal",
				accessor: "subTotal",
				maxWidth: 70,
				minWidth: 50,
				width: 60,
				textAlign: "right",
				Cell: props => <React.Fragment>{currency(props.value).format()}</React.Fragment>,
				//Cell: ({ value }) => (
				//	<NumberFormat value={value} displayType={'text'} thousandSeparator={true} prefix={'$'} />
				//),
				borderWidth: "2px",
				borderStyle: "solid",
			},
			{
				Header: "Total",
				accessor: "orderTotal",
				maxWidth: 70,
				minWidth: 50,
				width: 60,
				textAlign: "right",
				Cell: props => <React.Fragment>{currency(props.value).format()}</React.Fragment>,
				//Cell: ({ value }) => currency(value),
				borderWidth: "2px",
				borderStyle: "solid",
			},
		], []
	);

	return (
		<div className="data-table-container">
			<DataTable columns={columns} data={syncResults.objectSyncState} selectedDataRows={selectedSalesRows} handleSelected={handleSalesSelected}/>
		</div>
	);
};

export default SalesSyncDestinationResponse;