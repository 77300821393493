import React from 'react';
import { useState, useEffect } from "react";

const DisplaySyncCustomer = ({ customerList, syncState, saleOrderNumber, handleSelectedCustomer }) => {
	const [selectedCustomer, setSelectedCustomer] = useState(0);
//	const [usingCustomer, setUsingCustomer] = useState([selectedCustomer]);
	console.log(customerList[selectedCustomer]);
//	const [usingCustomer, setUsingCustomer] = useState({customerList[selectedCustomer]});
	const [usingCustomer, setUsingCustomer] = useState({...customerList[selectedCustomer]});
  	const [isCustomerVisible, setCustomerVisible] = useState(false);

  useEffect(() => {
	handleSelectedCustomer(saleOrderNumber, usingCustomer);
  }, [usingCustomer]);

  const showCustomer = () => {
    setCustomerVisible(true);
  };

  const hideCustomer = () => {
    setCustomerVisible(false);
  };
	
	function handleCustomerSelect(e) {
		//console.log("Selected customer target: ", e.target);
		//console.log("Selected customer value: ", e.target.value);
		//console.log("Selected customer key: ", e.target.key);
		//setSelectedCustomer(e.target.value);
		console.log(`customer count: ` + customerList.length);    		
		//console.log(`customers: ` + JSON.stringify(customerList));
    	setSelectedCustomer(e.target.value);
    	if (customerList.length === 0 || (customerList.length > 0 && e.target.value > 0 && e.target.value === customerList.length)) {
    		console.log(`setting empty`);
    	}
    	else {
			console.log('setting ' + customerList[e.target.value]);
		}
    	setUsingCustomer(customerList.length === 0 || (customerList.length > 0 && e.target.value > 0 && e.target.value === customerList.length) ? 
    		undefined
    		: customerList[e.target.value])
//    		{obj: undefined}
//    		: {obj: customerList[e.target.value]})

		console.log('calling handleSelectedCustomer');
		handleSelectedCustomer(saleOrderNumber, usingCustomer);
		//console.log(`set selected customer: ` + e.target.value);    		
		//console.log(`set using customer from list: ` + JSON.stringify(customerList[e.target.value]));
		//console.log(`set using customer: ` + JSON.stringify(usingCustomer));
	}

	if (syncState === 'NEEDS_CUSTOMER') {
		console.log('NEEDS_CUSTOMER: not synced customer: creating list');
		//console.log(`customerList size: ${customerList.length}`)
		//		{customerList.map((customer, key) => (
		//			console.log(`key: ${key}, customer: ` + JSON.stringify(customer))
		//		))}
		return (
			<React.Fragment>
			<select
				name="Customers"
				onChange={e => handleCustomerSelect(e)}
				value={selectedCustomer}
				onMouseEnter={showCustomer} onMouseLeave={hideCustomer}
			>
				{customerList.map((customer, key) => (
					<option key={key} value={key}>
						{customer.displayName}{customerList.length > 1 && '+'}
					</option>
				))}
				<option key={customerList.length} value={customerList.length}>Manually Enter Customer</option>
			</select>
		{/*console.log(`Selected Customer: ${selectedCustomer}`)*/}
		{/*console.log(`Using Customer: ` + JSON.stringify(usingCustomer))*/}
      {isCustomerVisible && (
        <div className="customer-popup">
          <p>
          	{usingCustomer === undefined && <span>Need to enter customer details</span>}
          	{usingCustomer !== undefined && <span>{usingCustomer.displayName}
          	{usingCustomer.primaryEmailAddr !== undefined && <span><br/>{usingCustomer.primaryEmailAddr}</span>}
          	{usingCustomer.primaryPhone !== undefined && usingCustomer.primaryPhone !== null && <span><br/>{usingCustomer.primaryPhone.freeFormNumber}</span>}
          	{usingCustomer.billAddr !== undefined && usingCustomer.billAddr !== null && <span><br/>{usingCustomer.billAddr.addressLine1}<br/>{usingCustomer.billAddr.city}, {usingCustomer.billAddr.state} {usingCustomer.billAddr.postalCode}</span>}</span>}
          </p>
        </div>
      )}
			</React.Fragment>
		);
	} else {
		console.log(`synced customer: {customerList[0].displayName}`);
		return <React.Fragment>{customerList ? customerList[0].displayName : undefined}</React.Fragment>;
	}
};

export default DisplaySyncCustomer;