import { takeLatest, all, call, put } from 'redux-saga/effects';

// need to select actions from interface
import { exchangePartnerActions } from '../../../interface/actions/exchange-partner.actions';

import { fetchExchangesSuccess, fetchExchangesFailed } from './exchange.action';

import { EXCHANGES_ACTION_TYPES } from './exchange-action.types';

export function* fetchExchangesAsync() {
	console.log('onFetchExchangesAsync');
	try {
		// call callable method (our interface call)
		const exchangesArray = yield call(exchangePartnerActions.getAvailableExchangePartners, 'exchanges');
		yield put(fetchExchangesSuccess(exchangesArray));
	} catch (error) {
		yield put(fetchExchangesFailed(error));
	}
}

// function* = generator function
// generator functions respond to actions
export function* onFetchExchanges() {
	console.log('onFetchExchanges');
	// responds to fetch start action
	yield takeLatest(
		EXCHANGES_ACTION_TYPES.FETCH_EXCHANGES_START,
		fetchExchangesAsync
	);
}

export function* exchangesSaga() {
	console.log('exchangesSaga called');
	yield all([call(onFetchExchanges)]);
}