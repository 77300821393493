export const tenantConstants = {
    TENANT_GET_REQUEST: 'TENANT_GET_REQUEST',
    TENANT_GET_SUCCESS: 'TENANT_GET_SUCCESS',
    TENANT_GET_FAILURE: 'TENANT_GET_FAILURE',

    TENANT_SAVE_REQUEST: 'TENANT_SAVE_REQUEST',
    TENANT_SAVE_SUCCESS: 'TENANT_SAVE_SUCCESS',
    TENANT_SAVE_FAILURE: 'TENANT_SAVE_FAILURE',

    GETTENANTOPTIONS_REQUEST: 'GETTENANTOPTIONS_REQUEST',
    GETTENANTOPTIONS_SUCCESS: 'GETTENANTOPTIONS_SUCCESS',
    GETTENANTOPTIONS_FAILURE: 'GETTENANTOPTIONS_FAILURE',

    EXCHANGE_AUTH_REQUEST: 'EXCHANGE_AUTH_REQUEST',
    EXCHANGE_AUTH_SUCCESS: 'EXCHANGE_AUTH_SUCCESS',
    EXCHANGE_AUTH_FAILUREE: 'EXCHANGE_AUTH_FAILURE',
};
