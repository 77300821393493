import { useSelector, useDispatch } from 'react-redux';

import { selectExchanges, selectExchangesMap, selectExchangesIsLoading } from '../../../store/reducers/exchanges/exchange.selector';
import { setCurrentTenant } from '../../../store/reducers/tenant/tenant.action';

import { tenantActions } from '../actions';
import { TenantActions } from '../../../interface/actions/tenant/tenant.actions';

import ExchangePartner from "./exchange-partner.component";

import {
	ExchangePartnersContainer,
	ExchangePartnersHeader,
	HeaderBlock,
} from "./exchange-partner.styles";

const ExchangePartnerList = ({ currentTenant }) => {
	//const exchangesMap = useSelector(selectExchangesMap);
	const exchanges = useSelector(selectExchanges);

	const dispatch = useDispatch();

	const getConfigHandler = (exchangePartner) => {
		console.log('exchangePartner: ', {exchangePartner});
		const { exchangeAuthType } = exchangePartner;

		const tenantExchangeConnection = currentTenant.tenantExchangeConnections.find(o => {
			console.log('looping tenantExchangeConnections: ', {o});
			//console.log(o);
			return o.exchangePartner.exchangePartnerId === exchangePartner.exchangePartnerId;
		})
		console.log('found tenantExchangeConnection: ', {tenantExchangeConnection});
		//console.log(tenantExchangeConnection);

		switch (exchangeAuthType) {
			case 'oauth2':
				return () => handleOAuth2(exchangePartner, tenantExchangeConnection);
			case 'qbo_auth':
				return () => handleQBOAuth(exchangePartner);
			case 'square_auth':
				return () => handleSquareAuth(exchangePartner);
			case 'basic_auth':
				return () => handleBasicAuth(exchangePartner);
			default:
				return null;
		};
	};

	const getRevokeHandler = (exchangePartner) => {
		const { exchangeAuthType } = exchangePartner;

		const tenantExchangeConnection = currentTenant.tenantExchangeConnections.find(o => {
			console.log(o);
			return o.exchangePartner.exchangePartnerId === exchangePartner.exchangePartnerId;
		})
		console.log("tenantExchangeConnection: " + tenantExchangeConnection);

		if (tenantExchangeConnection != null) {
			switch (exchangeAuthType) {
				case 'oauth2':
					return () => handleOAuth2Revoke(exchangePartner);
				case 'qbo_auth':
					return () => handleQBOAuthRevoke(exchangePartner);
				//case 'square_auth':
				//	return () => handleSquareAuthRevoke(exchangePartner);
				//case 'basic_auth':
				//	return () => handleBasicAuthRevoke(exchangePartner);
				default:
					return null;
			};
		}
	};

	const handleOAuth2 = async (exchangePartner) => {
		try {
			await TenantActions.oauth2ConnectExchange(currentTenant.tenantId, exchangePartner)
				.then(response => {
					console.log(response);
					console.log('Connected', response);
					//setTenantProfile(response.currentTenant);
					//dispatch(setCurrentUser(response.userProfile));
					dispatch(setCurrentTenant(response));
					//dispatch(...state);
				});
		}
		catch (error) {
			console.error('OAuth2 Configure encountered an error', error);
		}

		//dispatch(tenantActions.qboExchangeAuth(currentTenant.tenantId, exchangePartner.exchangePartnerId));
	};

	const handleOAuth2Revoke = async (exchangePartner) => {
		try {
			await TenantActions.oauth2RevokeExchange(currentTenant.tenantId, exchangePartner)
				.then(response => {
					console.log('Revoked', response);
					//setTenantProfile(response.currentTenant);
					//dispatch(setCurrentUser(response.userProfile));
					dispatch(setCurrentTenant(response));
					//dispatch(...state);
				});
		}
		catch (error) {
			console.error('OAuth2 Revoke encountered an error', error);
		}
	};

	const handleQBOAuth = async (exchangePartner) => {
		// call tenantServices.exchangeQBOAuth
		alert(`called handleQBOAuth - ${exchangePartner.exchangeName}`);
		try {
			await tenantActions.qboExchangeAuth(currentTenant.tenantId, exchangePartner.exchangePartnerId)
				.then(response => {
					console.log(response);
					console.log('Logged in', response);
					console.log('userProfile', response.userProfile);
					console.log('tenantProfile', response.currentTenant);
					//setUserProfile(response.userProfile);
					//setTenantProfile(response.currentTenant);
					//dispatch(setCurrentUser(response.userProfile));
					dispatch(setCurrentTenant(response.currentTenant));
					//dispatch(...state);
				});
		}
		catch (error) {
			console.error('QBO Auth in encountered an error', error);
		}

		//dispatch(tenantActions.qboExchangeAuth(currentTenant.tenantId, exchangePartner.exchangePartnerId));
	};

	const handleQBOAuthRevoke = async (exchangePartner) => {
		// call tenantServices.exchangeQBOAuth
		alert(`called handleQBOAuthRevoke - ${exchangePartner.exchangeName}`);
		try {
			await tenantActions.qboExchangeAuth(currentTenant.tenantId, exchangePartner.exchangePartnerId)
				.then(response => {
					console.log(response);
					console.log('Logged in', response);
					console.log('userProfile', response.userProfile);
					console.log('tenantProfile', response.currentTenant);
					//setUserProfile(response.userProfile);
					//setTenantProfile(response.currentTenant);
					//dispatch(setCurrentUser(response.userProfile));
					dispatch(setCurrentTenant(response.currentTenant));
					//dispatch(...state);
				});
		}
		catch (error) {
			console.error('QBO Auth in encountered an error', error);
		}

		//dispatch(tenantActions.qboExchangeAuth(currentTenant.tenantId, exchangePartner.exchangePartnerId));
	};

	const handleSquareAuth = async (exchangePartner) => {
		alert(`OAuth2 - ${exchangePartner.exchangeName}`);
		try {
			await tenantActions.squareExchangeAuth(currentTenant.tenantId, exchangePartner.exchangePartnerId)
				.then(response => {
					console.log(response);
					console.log('Logged in', response);
					console.log('userProfile', response.userProfile);
					console.log('tenantProfile', response.currentTenant);
					//setUserProfile(response.userProfile);
					//setTenantProfile(response.currentTenant);
					//dispatch(setCurrentUser(response.userProfile));
					dispatch(setCurrentTenant(response.currentTenant));
					//dispatch(...state);
				});
		}
		catch (error) {
			console.error('OAuth2 encountered an error', error);
		}

		//dispatch(tenantActions.qboExchangeAuth(currentTenant.tenantId, exchangePartner.exchangePartnerId));
	};

	const handleBasicAuth = (exchangePartner) => {
		// popup configuration - get username and password and save
		alert(`Basic auth - ${exchangePartner.exchangeName}`);

		dispatch(tenantActions.basicExchangeAuth(currentTenant.tenantId, exchangePartner.exchangePartnerId));
	}

	const getBasicAuthDetails = (exchangePartner) => {
		this.setState({ currentExchangePartner: exchangePartner });
		this.showModal();
	}

	const getBasicAuthDetailsBackup = (exchangePartner) => {
		this.setState({ currentExchangePartner: `${exchangePartner.exchangeName}` });
		this.showModal();
	}

	const showModal = () => {
		this.setState({
			showBasicAuth: true
		});
	};

	const hideModal = () => {
		this.setState({ showBasicAuth: false });
	};

	const saveBasicAuth = () => {
		var message = document.getElementById("epUserName").value;
		alert(message);

		let partnerId = this.state.currentExchangePartner.exchangePartnerId;
		let epUserName = document.getElementById("epUserName").value;
		let epPassword = document.getElementById("epPassword").value;

		const { currentTenant } = this.props;
		const { dispatch } = this.props;
		dispatch(tenantActions.basicExchangeAuth(currentTenant.tenantId, partnerId, epUserName, epPassword));

		this.setState({ showBasicAuth: false });
	};

	return (
		<ExchangePartnersContainer>
			<h3>Exchange Partners</h3>
			<ExchangePartnersHeader>
				<HeaderBlock>
					<span>Enabled</span>
				</HeaderBlock>
				<HeaderBlock>
					<span>Exchange</span>
				</HeaderBlock>
				<HeaderBlock>
					<span>Description</span>
				</HeaderBlock>
				<HeaderBlock>
					<span>Access</span>
				</HeaderBlock>
				<HeaderBlock>
					<span>System of Record</span>
				</HeaderBlock>
			</ExchangePartnersHeader>
			{exchanges && exchanges.map((exchangePartner) => (
				<ExchangePartner key={exchangePartner.exchangeCode} exchangePartner={exchangePartner} onConfigHandler={getConfigHandler(exchangePartner)} onRevokeHandler={getRevokeHandler(exchangePartner)} />
			))}

		</ExchangePartnersContainer>
	);
};

export default ExchangePartnerList;