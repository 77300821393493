import { TenantService } from '../../services/tenant/tenant.service'
import { TENANT_ACTION_TYPES } from './tenant.types'

const oauth2ConnectExchange = async (tenantId, exchangePartner) => {
	console.log(`in tenantActions.oauth2ConnectExchange (${tenantId}, ${exchangePartner.exchangePartnerId})`);

	try {
		await TenantService.connectExchangeOAuth2(tenantId, exchangePartner)
		.then(response => {
						console.log(response);
						console.log('Connected', response);
						//setTenantProfile(response.currentTenant);
						//dispatch(setCurrentUser(response.userProfile));
//						dispatch(setCurrentTenant(response.currentTenant));
						//dispatch(...state);
					});
	}
	catch (error) {
		console.error('oauth2ConnectExchange', error);
	}
}

const oauth2RevokeExchange = async (tenantId, exchangePartner) => {
	console.log(`in tenantActions.oauth2RevokeExchange (${tenantId}, ${exchangePartner.exchangePartnerId})`);

	try {
		return await TenantService.revokeExchangeOAuth2(tenantId, exchangePartner);
		
		//.then(response => {
		//				//console.log(response);
		//				console.log('Revoked', {response});
		//				//setTenantProfile(response.currentTenant);
		//				//dispatch(setCurrentUser(response.userProfile));
//		//				dispatch(setCurrentTenant(response.currentTenant));
						//dispatch(...state);
		//			});
	}
	catch (error) {
		console.error('oauth2RevokeExchange', error);
	}
}

const connectExchangePartner = async (tenantId, exchangePartner) => {
	const { exchangeAuthType } = exchangePartner;

	switch (exchangeAuthType) {
		case 'oauth2':
			return TenantService.connectExchangeOAuth2(exchangePartner);
		case 'qbo_auth':
			return TenantService.connectExchangeQboAuth(exchangePartner);
		case 'basic_auth':
			return TenantService.connectExchangeBasicAuth(exchangePartner);
		default:
			return null;
	};
};


export const TenantActions = {
	oauth2ConnectExchange,
	oauth2RevokeExchange, 
	connectExchangePartner,
};
