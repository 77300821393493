import React from 'react';
export const BasicAuth = ({ saveBasicAuth, exchangePartner }) => {
return (
<div>
  <div className="form-group">
      <label htmlFor="epUserName">User Name</label>
    <input className="form-control" id="epUserName" />
  </div> 
  
  <div className="form-group">
    <label htmlFor="epPassword">Password</label>
    <input type="password" className="form-control" id="epPassword"
     placeholder="password" 
    />
</div>
</div>
);
};
export default BasicAuth;