import { useContext } from 'react';
import { authHeader } from '../../../components/helpers';
import { UserContext } from '../../../contexts/user.context';
import { TenantContext } from '../../../contexts/tenant.context';

const syncOrdersEndpoint = "/api/orders/sync";
const syncCorrectedOrdersEndpoint = "/api/orders/sync/corrected";

export const SyncOrders = async (syncParams) => {
	const jwtToken = sessionStorage.getItem('jwtToken');
	console.log(`sync jwt token: ${jwtToken}`);

	const requestOptions = {
		method: 'POST',
		headers: {
			'Origin': `${process.env.APP_ORIGIN}`,
			'X-CSRF-Token': 'Fetch',
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Request-Method': 'POST',
			'Authorization': `${jwtToken}`,
			'jwtToken': `${jwtToken}`
		},
		crossDomain: true,
		credentails: 'include',
		body: JSON.stringify(
			{
				'sourceSystem': `${syncParams.sourceSystem}`,
				'destinationSystemList': [`${syncParams.destinationSystems}`],
				'extractToFile': `${syncParams.extractToFile}`,
				'filePath': `${syncParams.filePath}`,
				'startDate': `${syncParams.startDate}`,
				'endDate': `${syncParams.endDate}` 
			})
	};

	console.log(requestOptions);
	return fetch(`${process.env.REACT_APP_API}${syncOrdersEndpoint}`, requestOptions)
		.then(handleResponse);
}

export const SyncSelectedOrders = async (syncParams, selectedSaleOrders) => {
	const jwtToken = sessionStorage.getItem('jwtToken');
	//console.log(`sync jwt token: ${jwtToken}`);
	//console.log(`${selectedSaleOrders}`);
	console.log(selectedSaleOrders.selectedDataRows);
	//console.log(JSON.stringify(...selectedSaleOrders.selectedDataRows));
	const saleOrderArray = JSON.stringify(...selectedSaleOrders.selectedDataRows);
	const dataRows = selectedSaleOrders.selectedDataRows['0']; 
	console.log(JSON.stringify(
			{
				'sourceSystem': `${syncParams.sourceSystem}`,
				'destinationSystemList': [`${syncParams.destinationSystems}`],
				'selectedOrders': [...dataRows]
			}));

	const requestOptions = {
		method: 'POST',
		headers: {
			'Origin': `${process.env.APP_ORIGIN}`,
			'X-CSRF-Token': 'Fetch',
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Request-Method': 'POST',
			'Authorization': `${jwtToken}`,
			'jwtToken': `${jwtToken}`
		},
		crossDomain: true,
		credentails: 'include',
		body: JSON.stringify(
			{
				'sourceSystem': `${syncParams.sourceSystem}`,
				'destinationSystemList': [`${syncParams.destinationSystems}`],
				'selectedOrders': dataRows
			})
	};

	console.log(requestOptions);
	return fetch(`${process.env.REACT_APP_API}${syncCorrectedOrdersEndpoint}`, requestOptions)
		.then(handleResponse);
}

const handleResponse = async (response) => {
	console.log('handle sales.service response');
	console.log({ response });
	return response.json().then(json => {
		const data = json;

		if (!response.ok) {
			if (response.status === 401) {
				console.log('Error: ' + data + ', ' + response.getMessage());
			}

			const error = (data && data.message) || response.statusText;
			return Promise.reject(error);
		}

		console.log({ data });
		return data;
	});
}

export const SalesService = {
	SyncOrders,
	SyncSelectedOrders,
};
