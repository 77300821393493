import FormInput from '../../../../../components/form-input/form-input.component';

const subscriptionLevels = ['Trial', 'Basic', 'Premium']

const SubscriptionSelectorInput = ({ input }) => {
	console.log({ ...input });

	return (
		<div>
			<select {...input}>
				<option value="">Select level...</option>
				{subscriptionLevels.map(val => (
					<option value={val} key={val}>
						{val}
					</option>
				))}
			</select>
		</div>
	)
}

const SignUpFormSubscriptionPage = ({ onboardRequest, onChangeHandler, previousPage, onSubmitHandler }) => {
	console.log(onboardRequest);
	return (
		<form onSubmit={onSubmitHandler}>
			<div><h2>Subscription:</h2></div>
			<div>
				<SubscriptionSelectorInput
					onChange={onChangeHandler}
					name='account.subscriptionLevel'
				/>
				<FormInput
					label='Comments'
					type="textarea"
					onChange={onChangeHandler}
					name='comments'
					value={onboardRequest.comments}
				/>
			</div>

			<div>
				<button type="button" className="previous" onClick={previousPage}>
					Previous
				</button>
				<button type="submit" className="next" disabled={onboardRequest.submitted}>
					Submit
				</button>
			</div>
		</form>
	)
}

export default SignUpFormSubscriptionPage;