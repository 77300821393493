import { Component } from 'react';
import Card from '../card/card.component';
import './card-list.styles.css';

// component name must start with capital letter and be camel case to be recognized by React as a created component
class CardList extends Component {
	render() {
		//passed in values from <CardList myProp='' myProp2=1 />
		console.log(this.props);
		const { listElements } = this.props;

		return (
			<div className='card-list'>
				{listElements.map((monster) => {
					return (
						<Card monster={monster} />
					)
				})
				}
			</div>
		);
		// you cannot have multiple parent level html components being returned from a component
		// it must have 1 parent level component
		// cannot have <div/><div/>
		// you can have <div><div/><div/></div>
	}
}

export default CardList;
