export const defaultOnboardRequest = {
	systemAccess: {
		requestType: 'BASIC_AUTH',
		ipAddress: '',
		userName: '',
		password: '',
		confirmPassword: '',
		userProfile: {
			accessType: "Standard",
			firstName: '',
			lastName: '',
			userType: '',
			contact: {
				email: '',
				phone: '',
				mobile: '',
				preferredContactMethod: '',
				addressLine1: '',
				addressLine2: '',
				addressLine3: '',
				city: '',
				state: '',
				country: '',
				zipcode: ''
			}
		},
	},
	tenantProfile: {
		tenantCode: "GENERATE",
		companyName: '',
		legalCompanyName: "",
		primaryUrl: "",
		contact: {
			email: '',
			phone: '',
			mobile: '',
			preferredContactMethod: '',
			addressLine1: '',
			addressLine2: '',
			addressLine3: '',
			city: '',
			state: '',
			country: '',
			zipcode: ''
		},
		exchangePartners: [
			{ exchangeCode: 'QBO', exchangeName: 'QuickBooks Online' },
			{ exchangeCode: 'ECWID', exchangeName: 'ECWID' },
			{ exchangeCode: 'SQUARE', exchangeName: 'Square' },
			{ exchangeCode: 'STAMPS', exchangeName: 'Stamps.com' },
			{ exchangeCode: 'PIRATE', exchangeName: 'Pirate Ship' }
		],
		affiliations: [
			'QBO',
			'ECWID',
			'SQUARE',
			'STAMPS',
			'PIRATE'
		],
		subscription: {
			subscriptionLevel: '',
			billingCycle: 'annual',
			activationDate: ''
		},
	},
	comments: '',
	submitted: false

};
