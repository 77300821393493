import React from 'react';
import { useMemo } from 'react';

import DataTable from '../../../components/table/data-table.component';
import currency from 'currency.js';

const InventorySyncDestinationResponse = ({ syncResults }) => {

	const columns = React.useMemo(
		() => [
			//{
			//	Header: 'Select',
			//	accessor: 'selection', // This accessor is used for the checkbox column
			//},
			{
				Header: "SKU",
				accessor: "sku",
				maxWidth: 75,
				minWidth: 50,
				width: 75,
				textAlign: "left",
				borderWidth: "2px",
				borderStyle: "solid",
			},
			{
				Header: "Name",
				accessor: "name",
				maxWidth: 75,
				minWidth: 50,
				width: 75,
				textAlign: "left",
				borderWidth: "2px",
				borderStyle: "solid",
			},
			{
				Header: "Category",
				accessor: "category",
				maxWidth: 75,
				minWidth: 50,
				width: 75,
				textAlign: "left",
				borderWidth: "2px",
				borderStyle: "solid",
			},
			{
				Header: "Timestamp",
				accessor: "saleTimestamp",
				maxWidth: 100,
				minWidth: 50,
				width: 100,
				textAlign: "left",
				borderWidth: "2px",
				borderStyle: "solid",
			},
			{
				Header: "Sync Action",
				accessor: "syncAction",
				maxWidth: 100,
				minWidth: 50,
				width: 100,
				textAlign: "left",
				borderWidth: "2px",
				borderStyle: "solid",
			},
			{
				Header: "Sync State",
				accessor: "syncState",
				maxWidth: 100,
				minWidth: 50,
				width: 100,
				textAlign: "left",
				borderWidth: "2px",
				borderStyle: "solid",
			},
			{
				Header: "Price",
				accessor: "price",
				maxWidth: 70,
				minWidth: 50,
				width: 60,
				textAlign: "right",
				Cell: props => <React.Fragment>{currency(props.value).format()}</React.Fragment>,
				//Cell: ({ value }) => (
				//	<NumberFormat value={value} displayType={'text'} thousandSeparator={true} prefix={'$'} />
				//),
				borderWidth: "2px",
				borderStyle: "solid",
			},
			{
				Header: "Item Count",
				accessor: "itemCount",
				maxWidth: 75,
				minWidth: 50,
				width: 75,
				textAlign: "right",
				borderWidth: "2px",
				borderStyle: "solid",
			},
		], []
	);

	return (
		<div className="container">
			<DataTable columns={columns} data={syncResults.objectSyncState} />
		</div>
	);
};

export default InventorySyncDestinationResponse;