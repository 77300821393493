import { useState } from 'react';

import SignUpFormUserPage from './sign-up-form-user.component';
import SignUpFormCompanyPage from './sign-up-form-company.component';
import SignUpFormExchangesPage from './sign-up-form-exchanges.component';
import SignUpFormSubscriptionPage from './sign-up-form-subscription.component';

const SignUpForm = ({ onboardRequest, onChangeHandler, onSubmitHandler }) => {
	const [page, setPage] = useState(1);


	const nextPage = (event) => {
		event.preventDefault();
		setPage(page + 1);
	}

	const previousPage = (event) => {
		event.preventDefault();
		setPage(page - 1);
	}

	return (
		<div>
			{page === 1 && 
				<SignUpFormUserPage 
					onboardRequest={onboardRequest} 
					onChangeHandler={onChangeHandler} 
					onSubmitHandler={nextPage} 
				/>
			}
			{page === 2 && (
				<SignUpFormCompanyPage
					onboardRequest={onboardRequest} 
					onChangeHandler={onChangeHandler} 
					previousPage={previousPage}
					onSubmitHandler={nextPage}
				/>
			)}
			{page === 3 && (
				<SignUpFormExchangesPage
					onboardRequest={onboardRequest} 
					onChangeHandler={onChangeHandler} 
					previousPage={previousPage}
					onSubmitHandler={nextPage}
				/>
			)}
			{page === 4 && (
				<SignUpFormSubscriptionPage
					onboardRequest={onboardRequest} 
					onChangeHandler={onChangeHandler} 
					previousPage={previousPage}
					onSubmitHandler={onSubmitHandler}
				/>
			)}
		</div>
	);
}

export default SignUpForm;
