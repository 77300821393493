import { Component } from 'react';
import './search-box.styles.css';

// component name must start with capital letter and be camel case to be recognized by React as a created component
class SearchBox extends Component {
	render() {
		//passed in values from <CardList myProp='' myProp2=1 />
		console.log(this.props);
		const {className, onChangeHandler, placeHolder} = this.props;

		return (
			<input className={`search-box ${className}`} type='search' placeholder={placeHolder}
				onChange={onChangeHandler} />
		);
		// you cannot have multiple parent level html components being returned from a component
		// it must have 1 parent level component
		// cannot have <div/><div/>
		// you can have <div><div/><div/></div>
	}
}

export default SearchBox;
