import FormInput from '../../../../../components/form-input/form-input.component';

const renderError = ({ meta: { touched, error } }) =>
	touched && error ? <span>{error}</span> : false

const SignUpFormCompanyPage = ({ onboardRequest, onChangeHandler, previousPage, onSubmitHandler }) => {
	const { tenantProfile } = onboardRequest;
	
	return (
		<form onSubmit={onSubmitHandler}>
			<div><h2>Business Details:</h2></div>
			<div>
				<FormInput
					label='Company Name'
					type='text'
					required
					onChange={onChangeHandler}
					name='tenantProfile.companyName'
					value={tenantProfile.companyName}
				/>
				<FormInput
					label='Legal Company Name'
					type='text'
					required
					onChange={onChangeHandler}
					name='tenantProfile.legalCompanyName'
					value={tenantProfile.legalCompanyName}
				/>
				<FormInput
					label='Email'
					type='text'
					required
					onChange={onChangeHandler}
					name='tenantProfile.contact.email'
					value={tenantProfile.contact.email}
				/>
				<FormInput
					label='Phone'
					type='tel'
					required
					onChange={onChangeHandler}
					name='tenantProfile.contact.phone'
					value={tenantProfile.contact.phone}
				/>
				<FormInput
					label='Website'
					type='text'
					required
					onChange={onChangeHandler}
					name='tenantProfile.primaryUrl'
					value={tenantProfile.primaryUrl}
				/>
		        <strong>Contact Details:</strong>
				<FormInput
					label='Address Line 1'
					type='text'
					required
					onChange={onChangeHandler}
					name='tenantProfile.contact.addressLine1'
					value={tenantProfile.contact.addressLine1}
				/>
				<FormInput
					label='Address Line 2'
					type='text'
					onChange={onChangeHandler}
					name='tenantProfile.contact.addressLine2'
					value={tenantProfile.contact.addressLine2}
				/>
				<FormInput
					label='Address Line 3'
					type='text'
					onChange={onChangeHandler}
					name='tenantProfile.contact.addressLine3'
					value={tenantProfile.contact.addressLine3}
				/>
				<FormInput
					label='City'
					type='text'
					required
					onChange={onChangeHandler}
					name='tenantProfile.contact.city'
					value={tenantProfile.contact.city}
				/>
				<FormInput
					label='State'
					type='text'
					required
					onChange={onChangeHandler}
					name='tenantProfile.contact.state'
					value={tenantProfile.contact.state}
				/>
				<FormInput
					label='Postal Code'
					type='text'
					required
					onChange={onChangeHandler}
					name='tenantProfile.contact.zipcode'
					value={tenantProfile.contact.zipcode}
				/>
				<FormInput
					label='Country'
					value='USA'
					type='text'
					required
					onChange={onChangeHandler}
					name='tenantProfile.contact.country'
					value={tenantProfile.contact.country}
				/>
			</div>

			<div>
		        <button type='button' className='previous' onClick={previousPage}>
        		  	Previous
        		</button>
				<button type='submit' className='next'>
					Next
				</button>
			</div>
		</form>
	)
}

export default SignUpFormCompanyPage;