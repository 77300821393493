import FormInput from '../../../../../components/form-input/form-input.component';

const renderError = ({ meta: { touched, error } }) =>
	touched && error ? <span>{error}</span> : false

const SignUpFormUserPage = ({ onboardRequest, onChangeHandler, onSubmitHandler }) => {
	const { systemAccess } = onboardRequest;
	
	return (
		<form onSubmit={onSubmitHandler}>
			<div><h2>User Profile:</h2></div>
			<div className='group'>
				<label className='form-input-label shrink' >Requested User Permissions</label>
				<div>
					<label className='test-form-input-label'>Standard</label>
					<input 
						className='test-form-input-radio'
						type='radio'
						onChange={onChangeHandler}
						name='systemAccess.userProfile.userType'
						value='Standard'
					/>
					<label className='test-form-input-label'>Accountant</label>
					<input 
						className='test-form-input-radio'
						type='radio'
						onChange={onChangeHandler}
						name='systemAccess.userProfile.userType'
						value='Accountant'
					/>
				</div>
			</div>

			<div>
				<FormInput
					label='Email (User Name for login)'
					type='email'
					required
					onChange={onChangeHandler}
					name='systemAccess.userName'
					value={systemAccess.userName}
				/>
				<FormInput
					label='Password'
					type='password'
					required
					onChange={onChangeHandler}
					name='systemAccess.password'
					value={systemAccess.password}
				/>
				<FormInput
					label='Confirm Password'
					type='password'
					required
					onChange={onChangeHandler}
					name='systemAccess.confirmPassword'
					value={systemAccess.confirmPassword}
				/>
				<span>
					<FormInput
						label='First Name'
						type='text'
						required
						onChange={onChangeHandler}
						name='systemAccess.userProfile.firstName'
						value={systemAccess.userProfile.firstName}
					/>
					<FormInput
						label='Last Name'
						type='text'
						required
						onChange={onChangeHandler}
						name='systemAccess.userProfile.lastName'
						value={systemAccess.userProfile.lastName}
					/>
					<FormInput
						label='Phone number'
						type='tel'
						required
						onChange={onChangeHandler}
						name='systemAccess.userProfile.contact.phone'
						value={systemAccess.userProfile.contact.phone}
					/>
				</span>
			</div>

			<div>
				<button type='submit' className='next'>
					Next
				</button>
			</div>
		</form>
	)
}

export default SignUpFormUserPage;