import { useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import './dashboard.styles.scss';

//import { UserContext } from '../../contexts/user.context';
//import { TenantContext } from '../../contexts/tenant.context';
import { selectCurrentUser } from '../../store/reducers/user/user.selector';
import { selectCurrentTenant } from '../../store/reducers/tenant/tenant.selector';

import Authentication from '../SystemAccess/components/authentication/authentication.component';
import WidgetList from './components/widget-list/widget-list.component';

const WIDGET_ITEMS = [
	{ 'id': 'inventoryWidget', 'name': 'Inventory', 'to': '/inventory-main', 'component': '<InventoryWidget />' },
	{ 'id': 'salesWidget', 'name': 'Sales', 'to': '/sales-main', 'component': '<SalesWidget />' },
	{ 'id': 'barcodesWidget', 'name': 'Barcodes', 'to': '/barcodes-main', 'component': '<BarcodesWidget />' },
];


const Dashboard = () => {
	//const { userProfile } = useContext(UserContext);
	//const { tenantProfile } = useContext(TenantContext);
	const userProfile = useSelector(selectCurrentUser);
	const tenantProfile = useSelector(selectCurrentTenant);

	let navigate = useNavigate();
	const routeChange = (path) => {
		console.log('changing barcode route' + path);
		//let path = `print-barcodes`; 
		navigate(path);
	}

	useEffect(() => {
		let systemAccess = 'system-access';
		{!sessionStorage.getItem('jwtToken') && routeChange(systemAccess)}
		{!userProfile && routeChange(systemAccess)}
		{!tenantProfile && routeChange(systemAccess)}
	});
	
	return (
		<div className="page-container">
			<span className='dashboard-title'>Commerce Conx Dashboard</span>
			<span className='dashboard-subtitle'>Lets get synced</span>
			<WidgetList listElements={WIDGET_ITEMS} />
		</div>
	);
}

export default Dashboard;