import { useState, useContext } from 'react';

import { UserContext } from '../../../contexts/user.context';
import { TenantContext } from '../../../contexts/tenant.context';

import FormInput from '../../../components/form-input/form-input.component';
import Button from '../../../components/button/button.component';

const defaultFormFields = {
	searchCategory: '',
	searchItem: '',
	printPrice: '',
	itemsToPrint: [],
	submitted: false,
	loginAttempts: 0,
	printing: false,
};

const PrintBarcodes = () => {
	const [formFields, setFormFields] = useState(defaultFormFields);

	// get the setter method for the UserProfile session storage	
	const { userProfile, setUserProfile } = useContext(UserContext);
	const { tenantProfile, setTenantProfile } = useContext(TenantContext);
	console.log(`userProfile for printing: ${userProfile}`);

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormFields({ ...formFields, [name]: value });
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		console.log(formFields);
		const loginAttempts = formFields.loginAttempts + 1;
		setFormFields({ ...formFields, ['loginAttempts']: loginAttempts });
		setFormFields({ ...formFields, ['submitted']: true });
		setFormFields({ ...formFields, ['loggingIn']: true });
		console.log(formFields);
		console.log({ loginAttempts });
		//console.log({loggingIn});

		if (formFields.email && formFields.password) {
			try {
//				await printBarcodeActions.authenticateBasicUser(formFields.email, formFields.password)
//					.then(response => {
//						console.log('Logged in', response);
//						console.log('userProfile', response.userProfile);
//						console.log('tenantProfile', response.currentTenant);
//						setUserProfile(response.userProfile);
//						setTenantProfile(response.currentTenant);

//						<AuthStateChanged systemAccess={response} />;
//					});
			}
			catch (error) {
				console.error('sign in encountered an error', error);
			}
		}

		setFormFields({ ...formFields, ['submitted']: false });
		setFormFields({ ...formFields, ['printing']: false });
	};

	return (
		<div className="page-container">
			<h3>Barcode Printing</h3>
			<div>
				<form name='sign-in-form' onSubmit={handleSubmit}>
					<div>
						<FormInput
							label='Category'
							type='text'
							onChange={handleChange}
							name='searchItem'
							value={formFields.searchCategory}
						/>
						<FormInput
							label='Item (name, id, sku)'
							type='text'
							onChange={handleChange}
							name='searchItem'
							value={formFields.searchItem}
						/>
						<FormInput
							label='Print Price'
							type='checkbox'
							required
							onChange={handleChange}
							name='printPrice'
							value={formFields.printPrice}
						/>
					</div>
					<div className='buttons-container'>
						<Button type='submit'>Print</Button>
					</div>
				</form>
			</div>
		</div>
	)
}

export default PrintBarcodes;