import { useContext } from 'react';
import { authHeader } from '../../../components/helpers';
import { UserContext } from '../../../contexts/user.context';
import { TenantContext } from '../../../contexts/tenant.context';

const syncInventoryEndpoint = "/api/inventory/sync";

    function splitComponentsByComma(str){
        var ret = [];
        var arr = str.match(/(".*?"|[^",]+)(?=\s*,|\s*$)/g);
        for (let i in arr) {
            let element = arr[i];
            if ('"' === element[0]) {
                element = element.substr(1, element.length - 2);
            } else {
                element = arr[i].trim();
            }
            ret.push(element);
        }
        return ret;
    }

//export const SyncInventory = async (sourceSystem, destinationSystems, category, item) => {
export const SyncInventory = async (requestParams) => {
	const jwtToken = sessionStorage.getItem('jwtToken');
	console.log(`sync jwt token: ${jwtToken}`);

	console.log(requestParams);
	var destinationSystems = splitComponentsByComma(requestParams.destinationSystems);
	var searchCategories = requestParams.searchCategory === undefined ? "" : splitComponentsByComma(requestParams.searchCategory);
	var searchItems = requestParams.searchItem == undefined ? "" : splitComponentsByComma(requestParams.searchItem);
	console.log(searchCategories);
	
	const requestOptions = {
		method: 'POST',
		headers: {
			'Origin': `${process.env.APP_ORIGIN}`,
			'X-CSRF-Token': 'Fetch',
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Request-Method': 'POST',
			'Authorization': `${jwtToken}`,
			'jwtToken': `${jwtToken}`
		},
		crossDomain: true,
		credentails: 'include',
		body: JSON.stringify(
			{
				'sourceSystem': `${requestParams.sourceSystem}`,
				'destinationSystemList': destinationSystems,
				//'categoryList': `${requestParams.searchCategory}` == undefined ? undefined : `${splitComponentsByComma(requestParams.searchCategory)}`,
				'categoryList': searchCategories,
				'productList': searchItems,
				'includeActive': `${requestParams.includeActive}`,
				'includeInactive': `${requestParams.includeInactive}`,
				'onlyAddItemsWithQuantity': `${requestParams.onlyAddItemsWithQuantity}`
			})
	};

	console.log(requestOptions);
	return fetch(`${process.env.REACT_APP_API}${syncInventoryEndpoint}`, requestOptions)
		.then(handleResponse);
}

export const SyncPricingFromCSV = async (sourceSystem, destinationSystems) => {
	const jwtToken = sessionStorage.getItem('jwtToken');
	console.log(`sync jwt token: ${jwtToken}`);

	const requestOptions = {
		method: 'POST',
		headers: {
			'Origin': `${process.env.APP_ORIGIN}`,
			'X-CSRF-Token': 'Fetch',
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Access-Control-Request-Method': 'POST',
			'Authorization': `${jwtToken}`,
			'jwtToken': `${jwtToken}`
		},
		crossDomain: true,
		credentails: 'include',
		body: JSON.stringify(
			{
				'sourceSystem': `${sourceSystem}`,
				'destinationSystemList': [`${destinationSystems}`]
			})
	};

	console.log(requestOptions);
	return fetch(`${process.env.REACT_APP_API}${syncInventoryEndpoint}/pricing-csv`, requestOptions)
		.then(handleResponse);
}

const handleResponse = async (response) => {
	console.log('handle response');
	console.log({ response });

	if (response.status === 204) {
		console.log('No data processed');
		return {};
	} 
	
	return response.json().then(json => {
		const data = json;

		if (!response.ok) {
			if (response.status === 401) {
				console.log('Error: ' + data + ', ' + response.getMessage());
			}

			const error = (data && data.message) || response.statusText;
			return Promise.reject(error);
		}

		return data;
	});
}

export const InventoryService = {
	SyncInventory,
	SyncPricingFromCSV,
};
