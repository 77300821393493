import React from 'react';
import ReactDOM from 'react-dom'; // web based, react-native (mobile) react-vrr (Virtual Realitiy)
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'

import { store, persistor } from './store/store';

import App from './App';
import { UserProvider } from './contexts/user.context';
import { TenantProvider } from './contexts/tenant.context';
import { InventoryProvider } from './contexts/inventory.context';

import reportWebVitals from './reportWebVitals';

import './index.scss';

//  <React.StrictMode> <!-- warns and errors for deprecated methods/classes -->
ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<BrowserRouter>
					<TenantProvider>
						<InventoryProvider>
							<App />
						</InventoryProvider>
					</TenantProvider>
				</BrowserRouter>
			</PersistGate>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
