import React from 'react';
import { Link } from 'react-router-dom';
function Footer() {
    return (
      <footer>
        <nav>
              <Link to="/">Home</Link> |
              <Link to="Privacy.jsx"> Privacy </Link> |
              <Link to="EULA.jsx"> EULA </Link> 
              {/* Link to additional pages as needed */}
                   
        </nav>
      </footer>
    );
  }
  
  export default Footer;
  