import {createContext, useState, useEffect} from 'react';
import {SystemAccessService} from '../interface/services/system-access/system-access.service';

// actual value you want to access
export const UserContext = createContext({
	userProfile: null, 
	setUserProfile: () => null,	
})

// component
export const UserProvider = ({ children }) => {
	const [userProfile, setUserProfile] = useState(null);
	const value = {userProfile, setUserProfile};
	
//	useEffect (() => {
		// maybe systemAccess instead of userProfile here
		// may need to be moved to a SystemAccessContext class
//		const unsubscribe = SystemAccessService.onAuthStateChangedListener((userProfile) => {
//			if (userProfile) {
//				console.log(userProfile);
				//createUserDocumentFromAuth();
//			}
//			setUserProfile(userProfile);	
//		});
		
//		return unsubscribe;
//	}, []); // empty dependency array, only run once when componenet mounts
	
	return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}