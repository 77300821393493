import { Navigate } from 'react-router';

import BarcodesWidget from './barcodes-widget.component';
import InventoryWidget from './inventory-widget.component';
import SalesWidget from './sales-widget.component';

import './widget.styles.scss';


const WIDGET_COMPONENTS = [
	{ 'key': 'inventoryWidget', 'name': 'Inventory', 'to': '/inventory-main', 'component': <InventoryWidget /> },
	{ 'key': 'salesWidget', 'name': 'Sales', 'to': '/sales-main', 'component': <SalesWidget /> },
	{ 'key': 'barcodesWidget', 'name': 'Barcodes', 'to': '/print-barcodes', 'component': <BarcodesWidget /> },
];

const handleClick = (route) => {
	return (
		<Navigate to={route} />
	)
};

const getWidgetComponent = (widgetId) => {
	return WIDGET_COMPONENTS.find(widgetComponent => widgetComponent.key === widgetId);
};

// component name must start with capital letter and be camel case to be recognized by React as a created component
const Widget = ({widgetId}) => {
	//passed in values from <CardList myProp='' myProp2=1 />
	console.log(widgetId);
	console.log(WIDGET_COMPONENTS.find(widgetComponent => widgetComponent.key === widgetId));
	let currentWidgetComponent = getWidgetComponent(widgetId);
//	let currentWidgetComponent = WIDGET_COMPONENTS.find(widgetComponent => {
//		console.log(`${widgetComponent.key} : ${widgetId} : ${widgetComponent.key === widgetId}`);
//		return widgetComponent.key === {widgetId};
//	});
	console.log(currentWidgetComponent);
	const { name, id, component, to } = {currentWidgetComponent};
	console.log(`name: ${name}`);

	return (
		<div className='widget-container' key={currentWidgetComponent.id} >
			<span className='widget-title'>{currentWidgetComponent.name}</span>
			{currentWidgetComponent.component}
		</div>
	);
	// you cannot have multiple parent level html components being returned from a component
	// it must have 1 parent level component
	// cannot have <div/><div/>
	// you can have <div><div/><div/></div>
};

export default Widget;
