import { Component } from "react";
//import { connect } from 'react-redux';
import "./qbo-connect.styles.scss";
import qboConnectImage from "../images/C2QB_white_btn_lg_default.jpg";

const qboConnectUrl = `${process.env.REACT_APP_API}/api/quickbooks/online/connectToQuickbooks`;

class QboConnect extends Component {
	constructor(props) {
		super(props);
		this.state = {
			connectModal: false
		};
	}

	showConnectWindow() {
		console.log(qboConnectUrl);
		window.open(qboConnectUrl, 'Connect to QuickBooks Window', 'toolbar=0,status=0,width=800,height=650');
	};

	render() {
		return (
			<div>
			<a className="imgLink" onClick={this.showConnectWindow}>
				<img
					style={{ "height": "40px" }}
					src={qboConnectImage}
				/>
			</a>
			</div>
		);
	}
}

function mapStateToProps(state) {
	const { connectingExternal } = state.authentication;
	return {
		connectingExternal
	};
}

export default QboConnect; 
