export const TENANT_ACTION_TYPES = {
	
	SET_CURRENT_TENANT: 'SET_CURRENT_TENANT',
	
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',
    
    FETCH_TENANT_START: 'tenant/FETCH_TENANT_START',
    FETCH_TENANT_SUCCESS: 'tenant/FETCH_TENANT_SUCCESS',
    FETCH_TENANT_FAILED: 'tenant/FETCH_TENANT_FAILED',
};
