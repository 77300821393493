import FormInput from '../../../../../components/form-input/form-input.component';
import { useState, useEffect } from 'react';

const defaultExchangePartners = [
	{ exchangeCode: 'QBO', exchangeName: 'QuickBooks Online' },
	{ exchangeCode: 'ECWID', exchangeName: 'ECWID' },
	{ exchangeCode: 'SQUARE', exchangeName: 'Square' },
	{ exchangeCode: 'STAMPS', exchangeName: 'Stamps.com' },
	{ exchangeCode: 'PIRATE', exchangeName: 'Pirate Ship' }
]

const SignUpFormExchangesPage = ({ onboardRequest, onChangeHandler, previousPage, onSubmitHandler }) => {
	const { tenantProfile } = onboardRequest;
	const [availableExchangePartners, setAvailableExchangePartners] = useState(defaultExchangePartners);
	//const [ exchangePartners ] = tenantProfile.exchangePartners;
	console.log(tenantProfile);
	//console.log(exchangePartners);

	const [selectedExchangePartners, setSelectedExchangePartners] = useState(tenantProfile.exchangePartners);
	//	const { selectedExchangePartners } = [];

	//console.log(exchangePartners);
	console.log(tenantProfile.exchangePartners);

	const onCheckedChange = (event) => {
		console.log('onCheckedChange');
		const exchangeSearch = event.target.value;
		const selectedExchange = availableExchangePartners ? availableExchangePartners.find((exchange) => exchange.exchangeCode.includes(exchangeSearch)) : null;
		console.log(selectedExchange);

		if (!selectedExchange) {
			return;
		}

		if (event.target.checked) {
			console.log('checked');
			//			exchangePartners = () => ({
			//				...exchangePartners, selectedExchange
			//			});
			//setSelectedExchangePartners(...selectedExchangePartners, selectedExchange);
			setSelectedExchangePartners(selectedExchangePartners => ({
				...selectedExchangePartners, selectedExchange
			}));
		}
		else {
			console.log('unchecked');
			const index = selectedExchangePartners.findIndex(exchange => exchange.exchangeCode === selectedExchange.exchangeCode); //use id instead of index
			if (index > -1) { //make sure you found it
				//     			exchangePartners = () => (exchangePartners => exchangePartners.splice(index, 1));
				setSelectedExchangePartners(selectedExchangePartners => selectedExchangePartners.splice(index, 1));
			}
		}
	console.log ('modified exchange list');
		console.log(selectedExchangePartners);
		//event.target.name = 'tenantProfile.exchangePartners';
		//event.target.value = selectedExchangePartners;
		tenantProfile.exchangePartners = selectedExchangePartners;
		console.log(tenantProfile.exchangePartners);
		//onChangeHandler(event);
	}

	return (
		<form onSubmit={onSubmitHandler}>
			<div><h2>Exchange Partners:</h2></div>
			<span>Select affiliations:</span>
			<div><label><input type="checkbox" name="tenantProfile.exchangePartners[]" onChange={onCheckedChange} value="QBO" /> QuickBooks Online</label></div>
			<div><label><input type="checkbox" name="tenantProfile.exchangePartners[]" onChange={onCheckedChange} value="ECWID" /> ECWID</label></div>
			<div><label><input type="checkbox" name="tenantProfile.exchangePartners[]" onChange={onCheckedChange} value="SQUARE" /> Square</label></div>
			<div><label><input type="checkbox" name="tenantProfile.exchangePartners[]" onChange={onCheckedChange} value="STAMPS" /> Stamps.com</label></div>
			<div><label><input type="checkbox" name="tenantProfile.exchangePartners[]" onChange={onCheckedChange} value="PIRATE" /> Pirate Ship</label></div>

			<div>
				<button type="button" className="previous" onClick={previousPage}>
					Previous
				</button>
				<button type="submit" className="next">
					Next
				</button>
			</div>
		</form>
	)
}

export default SignUpFormExchangesPage;