import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router';

import { setCurrentUser } from '../../store/reducers/user/user.action';
import { setCurrentTenant } from '../../store/reducers/tenant/tenant.action';

//import { UserContext } from '../../contexts/user.context';
//import { TenantContext } from '../../contexts/tenant.context';
//import Dashboard from '../../stories/Dashboard/dashboard.story';
//import Authenticate from '../../stories/SystemAccess/components/authentication/authentication.component';

const Dashboard = () => {
	return (
		<div>
			<h1>I am the dashboard from auth changed</h1>
		</div>
	);

};

// auth observer
const onAuthStateChangedListener = (callback) => {
	// observer
	// need to create in backend as observable event
	//onAuthStateChanged(auth, callback, errorCallback, completedCallback);
}

const AuthStateChanged = ({systemAccess}) => {
	const dispatch = useDispatch();
	
	console.log(`AuthStateChanged component: ${systemAccess} `);
//	const { setUserProfile } = useContext(UserContext);
//	const { setTenantProfile } = useContext(TenantContext);
	
	// set context which should update rest of UI
//	setUserProfile(systemAccess ? systemAccess.userProfile : null);
//	setTenantProfile(systemAccess ? systemAccess.currentTenant : null);
	dispatch(setCurrentUser(systemAccess ? systemAccess.userProfile : null));
	dispatch(setCurrentTenant(systemAccess ? systemAccess.currentTenant : null));
	//dispatch(state);
	
	return (
		systemAccess ? <Navigate to="/dashboard" /> : <Navigate to="/system-access" />
//		systemAccess ? <Dashboard /> : <Authenticate />
	);
}

export default AuthStateChanged; 