import React from 'react';

import InventorySyncDestinationResponse from './inventory-sync-destination-response.component';

const InventorySyncResponse = ({ syncData }) => {
	return (
		<div className="container">
			{syncData.destinationSyncList.map(destinationSync => (
				<div className="container" key={destinationSync.destinationSystem}>
					<span>{syncData.sourceSystem} to {destinationSync.destinationSystem} Results</span>
					<InventorySyncDestinationResponse syncResults={destinationSync} />
				</div>
			))}
		</div>
	);
};

export default InventorySyncResponse;